import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Delete from "../../../../assets/images/icons/delete-outline.svg";
import { emptyCart } from "../../../../redux/cart/cartAction";
import {
  isAuthenticatedUser,
  getUserFromCookies,
} from "../../../../utils/Auth";
import { storePublicCartProducts, showMyCart } from "../../../../redux/cart/cartAction";

export default function StoreItems({
  group,
  group_store,
  getTotalItemPrice,
  removeCartItem,
  setMyCart,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = isAuthenticatedUser();
  const user = getUserFromCookies();
  const localCart = JSON.parse(localStorage.getItem("notstore-cart"));

  const clearCart = async () => {
    if (isAuth) {
       const emptyResponse = await dispatch(emptyCart({ user_id: user.id }));
       if (emptyResponse.status === "success") {
         dispatch(showMyCart());
       }
    } else {
      let updateCart = localCart;
      updateCart.products = null;
      updateCart.packages = null;

      const response = await dispatch(storePublicCartProducts(updateCart));
      if (response) {
        localStorage.setItem(
          "notstore-cart-items",
          JSON.stringify(response?.data)
        );
        localStorage.setItem("notstore-cart", JSON.stringify(updateCart));
        setMyCart(response?.data);
      }
    }
  };
  return (
    <div className="rounded-lg bg-white sm:max-w-3xl overflow-hidden border px-4 py-5 mb-6 shadow-lg">
      <h3 className="text-xl font-medium mb-6 pb-3 border-b">
        Product Summary ({group_store[group]?.[0]?.product_outlet_name})
      </h3>

      {group_store[group]?.length > 0 &&
        group_store[group]?.map((product, i) => (
          <div key={i}>
            <div className="flex justify-between items-start mb-5 ">
              <div className="flex">
                <span className="w-6 mr-10">{product.quantity}X</span>
                <div>
                  <p className="mb-2">
                    {product?.food_name || product?.merchandise_name}
                  </p>
                  {product?.attributes?.length > 0 &&
                    product?.attributes?.map((attr, i) => (
                      <p key={i} className="text-sm">
                        {attr.quantity}x {attr?.name}
                      </p>
                    ))}
                </div>
              </div>
              <div className="flex gap-4">
                <p>
                  ₦
                  {product?.food_amount
                    ? getTotalItemPrice(product, product?.food_amount)
                    : getTotalItemPrice(product, product?.merchandise_amount)}
                </p>
                <button
                  type="button"
                  onClick={() => removeCartItem(product?.delete_id)}
                >
                  <img src={Delete} alt="icon" className="mr-2" />
                </button>
              </div>
            </div>
          </div>
        ))}
      <div className="border-t border-[#DADADA] mt-8">
        <div className="justify-between flex w-full">
          <button
            type="button"
            className="text-lg text-primary-500 font-medium py-3 cursor-pointer"
            onClick={() => navigate(`/store/${group}`)}
          >
            + Add More Items
          </button>
          <button
            type="button"
            className="text-lg text-primary-500 font-medium py-3 cursor-pointer"
            onClick={() => clearCart()}
          >
            Clear Cart
          </button>
        </div>
      </div>
    </div>
  );
}
