import React, { Fragment, useState } from "react";

import { useDispatch } from "react-redux";
import Button from "../../components/buttons/buttons";
import Container from "../../components/container/container";

import { SignUpModal } from "../../components/modals/auth/signUpModal";
import { Menu, Transition } from "@headlessui/react";
import LogoWhiteImage from "../../assets/images/pictures/Not Store Silver Logo.png";
import LogoDarkImage from "../../assets/images/pictures/Not Store Main Logo.png";
import DropdownArrowSvg from "../../assets/images/icons/dropdown-arrow.svg";

import DropupArrowSvg from "../../assets/images/icons/dropup-arrow.svg";

import BlackDropdownArrowSvg from "../../assets/images/icons/blackdropdown.svg";

import BlackDropupArrowSvg from "../../assets/images/icons/blackdropup.svg";
import ORDERS from "../../assets/images/icons/orders.svg";
import PROFILE from "../../assets/images/icons/profile.svg";
import { PIMAGE } from "../../assets/images/pictures/images";

import { isAuthenticatedUser, getUserFromCookies } from "../../utils/Auth";
import { logoutUserLocallyAction } from "../../redux/auth/unAuthActions";
import { BsMoonStars } from "react-icons/bs";

const userNavigation = [
  {
    name: "Profile",
    href: "/profile/info",
    image: <img src={PROFILE} alt="icon" />,
  },
  {
    name: "Orders",
    href: "/profile/orders",
    image: <img src={ORDERS} alt="icon" />,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function LandingPage() {
  const dispatch = useDispatch();

  const isAuth = isAuthenticatedUser();
  const user = getUserFromCookies();
  let [isOpen, setIsOpen] = useState(false);
  let [isDark, setIsDark] = useState(true);
  const [show, setShow] = useState(null);

  const allFaq = [
    {
      title: "How do i set up my store on Not Store?",
      description:
        "To set up your store, start by messaging the Not Store bot and following the setup prompts. You will be asked to provide your store name, contact information, and a description of your store.",
    },
    {
      title: "How do i add new products to my store?",
      description:
        "To add new products, click on the “Create” button and follow the prompts to enter your product details. Once completed, save the information to list the new product in your store.",
    },
    {
      title: "How do i update or remove a product?",
      description:
        "To remove a product, search for the desired item, select it, and then click the “Remove” button at the bottom of the page to delete it from your store. If you need to update a product, make the necessary changes to the product details and then click “Save.”",
    },
    {
      title: "How do i deactivate a product?",
      description:
        "To deactivate a product and make it unavailable, search for the product and turn off the “Availability” button. This will remove it from your product display page.",
    },
    {
      title: "How are payments processed?",
      description:
        "Payments are processed through the Telegram wallet linked to your account. Customers can pay directly through the bot, and funds will be deposited into your wallet.",
    },
    {
      title: "Can i track my sales?",
      description:
        "Yes! To track your sales, click on “Order History.” This will display your sales history, including details of each transaction and the total amount earned.",
    },
    {
      title: "How do i withdraw funds from my Telegram Wallet",
      description:
        "You can withdraw funds by accessing your Telegram wallet and following the withdrawal process. Ensure your bank details are correctly linked to avoid any issues.",
    },
    {
      title: "How can customers contact me for more inquiries",
      description:
        "Customers can send messages directly to your store through the bot. You'll receive these messages in your Telegram chat and can respond promptly.",
    },
    {
      title: "Returns or Refunds?",
      description:
        "Establish a return and refund policy and communicate it clearly in your store description. Handle returns and refunds directly with the customer, and update the bot on the status of the transaction.",
    },
    {
      title: "Technical issues with the Not Store bot?",
      description:
        "To report a technical issue, click on the “Contact Support” button and provide a detailed description of the problem. Our team will assist you promptly.",
    },
    {
      title: "Improving Visibility and sales for my store",
      description:
        "Promote your store on social media, participate in Telegram groups relevant to your products, and offer promotions or discounts. Regularly updating your product listings and engaging with customers can also help boost sales.",
    },
    {
      title: "Store and customer data security",
      description:
        "We prioritize security and use encryption to protect your data. Ensure your Telegram account is secured with two-factor authentication and keep your login details confidential.",
    },
    {
      title: "Suspect Fraudulent activity? do the following...",
      description:
        "If you suspect any fraudulent activity, please report it immediately by clicking on the “Contact Support” button and providing a detailed description of the suspicious activity. Our team will investigate and take appropriate action.",
    },
  ];

  function closeModal() {
    setIsOpen(false);
  }

  function logoutUser() {
    dispatch(logoutUserLocallyAction());
  }

  function capitalize(word) {
    const words = word.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(" ");
  }

  function getInitials(word) {
    const words = word.split(" ");
    return words.map((word) => {
      return word[0].toUpperCase();
    });
  }

  return (
    <>
      <div
        style={{ backgroundColor: isDark ? "#080A0C" : "white" }}
        data-test="landingPage"
      >
        <div className=" bg-top sm:bg-cover ">
          <Container>
            <div className="relative ">
              <div className="absolute top-0 right-0 py-5 flex items-center gap-4">
                <BsMoonStars
                  color={isDark ? "white" : "black"}
                  onClick={() => setIsDark(!isDark)}
                  size={25}
                />

                {isAuth && (
                  <Menu
                    as="div"
                    className="hidden sm:block flex-shrink-0 relative ml-5"
                  >
                    {/* Profile dropdown */}

                    <Menu.Button className="bg-white rounded-full flex focus:outline-none items-center">
                      {user.picture ? (
                        <img
                          src={PIMAGE}
                          className="w-8 h-8 mr-1 rounded-full"
                          alt="user"
                        />
                      ) : (
                        <div className="w-[34px] h-[34px] rounded-full text-md bg-gray-200 flex items-center justify-center mr-1">
                          {getInitials(user?.name)}
                        </div>
                      )}

                      <span>{capitalize(user?.name)}</span>
                    </Menu.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block py-2 px-4 text-sm text-gray-700"
                                )}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                        <button
                          className="block py-2 px-4 text-sm text-gray-700"
                          onClick={logoutUser}
                        >
                          Sign out
                        </button>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                )}
                {!isAuth && <SignUpModal show={isOpen} onClose={closeModal} />}
              </div>
            </div>
            <div
              style={{ height: "100vh" }}
              className="flex flex-col items-center py-16"
            >
              {isDark ? (
                <img
                  src={LogoWhiteImage}
                  alt="logo-white"
                  style={{ marginTop: "30vh" }}
                  className="w-[650px] object-cover  mt-24"
                />
              ) : (
                <img
                  src={LogoDarkImage}
                  alt="logo-dark"
                  style={{ marginTop: "30vh" }}
                  className="w-[650px] object-cover  mt-24"
                />
              )}

              <div className="flex mb-20 mt-10 justify-center gap-6">
                <Button.Secondary
                  title={"Create Your Store"}
                  onClick={() =>
                    window.location.replace(
                      "https://t.me/thenotstorebot/thenotstorebot"
                    )
                  }
                  style={{
                    fontSize: "20px",
                    backgroundColor: "rgba(36, 107, 253, 1)",
                    borderWidth: "4px",
                    color: "white",
                    borderRadius: "8px",
                  }}
                  className=" sm:block px-3 w-[200px] rounded-none"
                />

                <Button.Secondary
                  title={"Help"}
                  onClick={() => {
                    document
                      .getElementById("faq-element")
                      .scrollIntoView({ behavior: "smooth" });
                  }}
                  style={{
                    fontSize: "20px",
                    backgroundColor: "rgba(36, 107, 253, 1)",
                    borderWidth: "4px",
                    color: "white",
                    borderRadius: "8px",
                  }}
                  className=" sm:block px-3 w-[130px] rounded-none"
                />
              </div>
            </div>
          </Container>
        </div>

        <Container>
          <div id="faq-element" className="flex flex-col px-5 md:px-5">
            {allFaq.map((item, index) => (
              <div
                key={index}
                className="p-4 mb-2.5 rounded-lg shadow-md cursor-pointer"
                style={{
                  backgroundColor: isDark ? "rgba(18, 18, 18, 1)" : "white",
                }}
                onClick={() =>
                  show === index ? setShow(null) : setShow(index)
                }
              >
                <div className="flex justify-between items-center w-full">
                  <p
                    className="text-sm font-medium leading-4 mb-1"
                    style={{ color: isDark ? "white" : "black" }}
                  >
                    {item?.title}
                  </p>

                  <img
                    src={
                      show === index
                        ? isDark
                          ? DropupArrowSvg
                          : BlackDropupArrowSvg
                        : isDark
                        ? DropdownArrowSvg
                        : BlackDropdownArrowSvg
                    }
                    alt="Toggle Icon"
                    className="mr-3"
                  />
                </div>

                {show === index && (
                  <p
                    className="text-xs leading-[1.4] mb-3 mt-6"
                    style={{ color: isDark ? "white" : "black" }}
                  >
                    {item?.description}
                  </p>
                )}
              </div>
            ))}
          </div>
        </Container>
      </div>
    </>
  );
}

export default LandingPage;
