import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { groupBy } from "../../../helpers/groupBy";
//components
import Container from "../../../components/container/container";
import NavBarUser from "../../../components/navigation/NavBarUser";
//icons
import Stopwatch from "../../../assets/images/icons/stopwatch.svg";
import Map from "../../../assets/images/icons/pin-round.svg";
import EmptyCart from "../../../assets/images/emptyState/cart.svg";
import Checkout from "./components/Checkout";
import { Beat } from "../../../plugins/spinners.plugin";
import { cleanInput } from "../../../helpers/cleanInput";
import RestaurantItems from "./productType/Restaurant";
import PackageItems from "./productType/Package";
import StoreItems from "./productType/Store";
import {
  showMyCart,
  showMyCartNoProperties,
  editCart,
  updateCoupon,
  emptyCart,
  storePublicCartProducts,
} from "../../../redux/cart/cartAction";
import { getMyAddressesAction } from "../../../redux/address/addressActions";
import { isAuthenticatedUser, getUserFromCookies } from "../../../utils/Auth";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Cart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuth = isAuthenticatedUser();
  const user = getUserFromCookies();
  const localCart = JSON.parse(localStorage.getItem("notstore-cart"));

  const [myCart, setMyCart] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [checkout_codes, setCheckoutCodes] = useState({
    checkout_code_slug: "",
  });
  const { checkout_code_slug } = checkout_codes;

  const { myAddresses } = useSelector((state) => state.address);
  const { myCarts, updatingCoupon, myCartNoProperties, cartProducts } =
    useSelector((state) => state.cart);
  const group_restaurant = groupBy(myCart?.products, "restaurant_id");
  const group_store = groupBy(myCart?.products, "store_id");

  let currentAddress = myAddresses?.data?.filter(function (address) {
    return address.address_selected === true;
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isAuth) {
      dispatch(getMyAddressesAction());
      dispatch(showMyCart());
      dispatch(showMyCartNoProperties());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAuth) {
      myCarts && setMyCart(myCarts);
      return;
    } else {
      // const cartItems = JSON.parse(localStorage.getItem("notstore-cart-items"));
      // console.log("cartItems", cartItems);
      if (cartProducts?.data) {
        setMyCart(cartProducts?.data);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myCarts, cartProducts]);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    e.preventDefault();
    setCheckoutCodes({ ...checkout_codes, [name]: value });
  };

  function getTotalItemPrice(product, price) {
    let foodPrice = Number(price) * Number(product.quantity);
    let totalPrice = 0;
    for (let i = 0; i < product?.attributes?.length; i++) {
      totalPrice +=
        Number(product?.attributes[i]?.amount) *
        Number(product?.attributes[i]?.quantity);
    }
    return numberWithCommas(Number(totalPrice) + Number(foodPrice));
  }

  const applyCoupon = async () => {
    const localCart = JSON.parse(localStorage.getItem("notstore-cart"));
    setSubmitted(true);
    if (isAuth) {
      if (checkout_code_slug) {
        setSubmitted(false);
        try {
          const response = await dispatch(
            updateCoupon({
              user_id: user?.id,
              checkout_codes: [checkout_codes],
            })
          );
          if (response?.status_code === 200) {
            dispatch(showMyCart());
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    } else {
      if (localCart) {
        let updateCart = localCart;
        //if there are items in the local cart
        if (checkout_code_slug) {
          updateCart.checkout_codes = [checkout_codes];
        }
        try {
          cleanInput(updateCart);
          const response = await dispatch(storePublicCartProducts(updateCart));
          if (response) {
            localStorage.setItem(
              "notstore-cart-items",
              JSON.stringify(response?.data)
            );
            localStorage.setItem("notstore-cart", JSON.stringify(updateCart));
          }
        } catch (error) {
          console.log("error", error);
        }
      } else {
        let updateCart = {
          checkout_codes: [checkout_codes],
        };
        try {
          cleanInput(updateCart);
          const response = await dispatch(storePublicCartProducts(updateCart));
          if (response) {
            localStorage.setItem(
              "notstore-cart-items",
              JSON.stringify(response?.data)
            );
            localStorage.setItem("notstore-cart", JSON.stringify(updateCart));
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    }
  };

  const removeCoupon = async () => {
    const localCart = JSON.parse(localStorage.getItem("notstore-cart"));
    if (isAuth) {
      if (
        myCarts?.coupons?.length > 0 ||
        myCarts?.promotion_codes?.length > 0
      ) {
        try {
          const response = await dispatch(
            updateCoupon({
              user_id: user?.id,
              checkout_codes: null,
              coupons: null,
              promotion_codes: null,
            })
          );
          if (response?.status_code === 200) {
            dispatch(showMyCart());
            setCheckoutCodes({ checkout_code_slug: "" });
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    } else {
      if (localCart) {
        let updateCart = localCart;
        updateCart.checkout_codes = null;
        updateCart.coupons = null;
        updateCart.promotion_codes = null;
        //if there are items in the local cart
        try {
          cleanInput(updateCart);
          const response = await dispatch(storePublicCartProducts(updateCart));
          if (response) {
            localStorage.setItem(
              "notstore-cart-items",
              JSON.stringify(response?.data)
            );
            localStorage.setItem("notstore-cart", JSON.stringify(updateCart));
            setCheckoutCodes({ checkout_code_slug: "" });
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    }
  };

  //remove cart product items
  const removeCartProductItem = async (delete_id) => {
    //if user is logged in, delete from online cart items
    //else delete from local cart items
    const newProducts = myCart.products.filter(
      (product) => product.delete_id !== delete_id
    );
    setMyCart({ ...myCart, products: newProducts });
    if (isAuth) {
      try {
        //if the total products remaining is greater than 0, update cart.
        //else if the last product was removed, empty the cart then update
        //the cart with whatever is remaining after deleting the item
        if (newProducts.length > 0) {
          let newObj = { ...myCart, products: newProducts };
          cleanInput(newObj);
          const response = await dispatch(
            editCart({
              user_id: user.id,
              ...newObj,
            })
          );
          response && dispatch(showMyCart());
        } else {
          let newObj = { ...myCart, products: newProducts };
          cleanInput(newObj);
          const emptyResponse = await dispatch(
            emptyCart({ user_id: user?.id })
          );
          if (emptyResponse.status === "success") {
            const response = await dispatch(
              editCart({
                user_id: user.id,
                ...newObj,
              })
            );
            response && dispatch(showMyCart());
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      //updating cart involves getting what is left from deleting,
      //updating the public cart products onlune and updating the localhost
      let updateCart = localCart;
      updateCart.products = newProducts;
      if (newProducts.length > 0) {
        try {
          const response = await dispatch(storePublicCartProducts(updateCart));
          if (response) {
            localStorage.setItem(
              "notstore-cart-items",
              JSON.stringify(response?.data)
            );
            localStorage.setItem("notstore-cart", JSON.stringify(updateCart));
            setMyCart(response?.data);
          }
        } catch (error) {
          console.log("error", error);
        }
      } else {
        try {
          const response = await dispatch(
            storePublicCartProducts({ ...updateCart, products: null })
          );
          if (response) {
            localStorage.setItem(
              "notstore-cart-items",
              JSON.stringify(response?.data)
            );
            localStorage.setItem("notstore-cart", JSON.stringify(updateCart));
            setMyCart(response?.data);
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    }
  };

  //remove cart package items
  const removeCartPackageItem = async (delete_id) => {
    //if user is logged in, delete from online cart items
    //else delete from local cart items
    const toViewCart = myCart.packages.filter(
      (pack) => pack.delete_id !== delete_id
    );
    setMyCart({ ...myCart, packages: toViewCart });

    if (isAuth) {
      const newPackages = myCartNoProperties.packages.filter(
        (pack) => pack.delete_id !== delete_id
      );
      try {
        if (newPackages.length > 1) {
          let newObj = { ...myCart, packages: newPackages };
          cleanInput(newObj);
          const response = await dispatch(
            editCart({
              user_id: user.id,
              ...newObj,
            })
          );
          response && dispatch(showMyCart());
        } else {
          let newObj = { ...myCart, packages: newPackages };
          cleanInput(newObj);
          const emptyResponse = await dispatch(emptyCart({ user_id: user.id }));
          if (emptyResponse.status === "success") {
            const response = await dispatch(
              editCart({
                user_id: user.id,
                packages: null,
                ...newObj,
              })
            );
            response && dispatch(showMyCart());
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      //updating cart involves getting what is left from deleting,
      //updating the public cart products onlune and updating the localhost
      let updateCart = localCart;
      updateCart.packages = toViewCart;

      if (toViewCart?.length > 0) {
        // try {
        //   const response = await dispatch(storePublicCartProducts(updateCart));
        //   if (response) {
        //     localStorage.setItem(
        //       "notstore-cart-items",
        //       JSON.stringify(response?.data)
        //     );
        //     localStorage.setItem("notstore-cart", JSON.stringify(response?.data));
        //     setMyCart(response?.data);
        //   }
        // } catch (error) {
        //   console.log("error", error);
        // }
      } else {
        try {
          const response = await dispatch(
            storePublicCartProducts({ ...updateCart, packages: null })
          );
          if (response) {
            localStorage.setItem(
              "notstore-cart-items",
              JSON.stringify(response?.data)
            );
            localStorage.setItem("notstore-cart", JSON.stringify(response?.data));
            setMyCart(response?.data);
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    }
  };

  function getCartTotal() {
    //check if the user is logged in first if the user is login,
    //get the total from the sum of the online cart packages and products
    //else get the total from the local storage package and products
    if (isAuth && (myCart?.products || myCart?.packages)) {
      let product_no = myCart?.products ? Number(myCart?.products?.length) : 0;
      let package_no = myCart?.packages ? Number(myCart?.packages?.length) : 0;
      return product_no + package_no;
    } else {
      if (localCart?.products || localCart?.packages) {
        let product_local = localCart?.products
          ? Number(localCart?.products?.length)
          : 0;
        let package_local = localCart?.packages
          ? Number(localCart?.packages?.length)
          : 0;
        return product_local + package_local;
      } else {
        return 0;
      }
    }
  }

  const cartTotal = getCartTotal();

  const clearCart = async () => {
    if (isAuth) {
      const emptyResponse = await dispatch(emptyCart({ user_id: user.id }));
      if (emptyResponse.status === "success") {
        dispatch(showMyCart());
      }
    } else {
      let updateCart = localCart;
      updateCart.products = null;
      updateCart.packages = null;

      const response = await dispatch(storePublicCartProducts(updateCart));
      if (response) {
        localStorage.setItem(
          "notstore-cart-items",
          JSON.stringify(response?.data)
        );
        localStorage.setItem("notstore-cart", JSON.stringify(updateCart));
        setMyCart(response?.data);
      }
    }
  };

  return (
    <div data-test="cartComponent">
      <NavBarUser />
      <div className="shadow-md mb-6 sm:mb-16">
        <Container>
          <div className="py-4 sm:pt-12 sm:pb-6">
            <h2
              className="text-[#333333] text-3xl sm:text-5xl font-bold mb-0 sm:mb-4"
              style={{ lineHeight: "64px" }}
            >
              My Cart
            </h2>
            <p className="text-lg text-gray-400 -mt-1 sm:mt-0">
              {cartTotal} Item
              {cartTotal > 1 ? "s" : ""} in the cart
            </p>
          </div>
        </Container>
      </div>

      <Container>
        <main className="pb-8">
          <div className="max-w-3xl mx-auto lg:max-w-7xl">
            {/* Main 3 column grid */}
            <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-16">
              {/* Left column */}
              <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                <section aria-labelledby="section-1-title">
                  <h2 className="sr-only" id="section-1-title">
                    Section title
                  </h2>
                  {/* Your content */}

                  <div>
                    {/* Delivery details i.e: user address */}
                    <div className="sm:max-w-3xl mb-6">
                      <h3 className="text-2xl font-medium mb-2">
                        Delivery details
                      </h3>
                      <p>
                        Please note, before proceeding to checkout, kindly
                        ensure you have selected the correct delivery service
                        area to avoid any potential additional charges for your
                        package.
                      </p>
                    </div>

                    <div className="rounded-lg bg-white sm:max-w-3xl overflow-hidden border px-4 py-5 mb-8">
                      <div className="flex mb-4">
                        <img src={Stopwatch} alt="icon" className="mr-2" />
                        <p>Delivered in 30-40 mins</p>
                      </div>

                      <div className="flex justify-between">
                        <div className="flex">
                          <img src={Map} alt="icon" className="mr-2" />
                          {currentAddress?.length > 0 ? (
                            currentAddress?.map((address, i) => (
                              <p key={i}>
                                {address?.address}, {address?.city},{" "}
                                {address?.country},{" "}
                              </p>
                            ))
                          ) : (
                            <p>
                              {isAuth ? (
                                "You have not set a current delivery address"
                              ) : (
                                <span className="text-gray-500">
                                  You need to login to set a delivery address
                                </span>
                              )}
                            </p>
                          )}
                        </div>

                        {isAuth ? (
                          <a
                            className="text-primary-500"
                            href="/profile/addresses"
                          >
                            Set Address
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    {/* Empty Cart */}
                    {(myCart?.products?.length === 0 ||
                      myCart?.products === null) &&
                      (myCart?.packages?.length === 0 ||
                        myCart?.packages === null) && (
                        <div className="rounded-lg bg-white sm:max-w-3xl overflow-hidden border px-4 py-5 mb-6">
                          <h3 className="text-xl font-medium mb-6 pb-3 border-b">
                            Order Summary
                          </h3>

                          <img
                            src={EmptyCart}
                            alt="Empty"
                            className="mt-8 h-[160px] w-auto mx-auto"
                          />

                          <div className="text-center my-6">
                            <p className="text-xl font-bold py-2">
                              Your cart is empty
                            </p>
                            <p className="text-[#676565]">
                              Tap “Discover More Products” to continue
                            </p>
                          </div>

                          <div className="border-t border-[#DADADA] mt-8">
                            <div className="justify-between flex w-full">
                              <button
                                className="text-lg text-primary-500 font-medium py-3 cursor-pointer"
                                onClick={() => navigate("/user/home")}
                              >
                                + Discover More Products
                              </button>

                              <button
                                type="button"
                                disabled
                                className="text-lg text-[#676565] font-medium py-3"
                              >
                                Clear Cart
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                    {/*Cart Product list based on outlet i.e restaurant, store etc. */}
                    {myCart?.products?.length > 0 &&
                      Object.keys(group_restaurant).map(
                        (group, i) =>
                          group !== "undefined" && (
                            <RestaurantItems
                              key={i}
                              group={group}
                              group_restaurant={group_restaurant}
                              getTotalItemPrice={getTotalItemPrice}
                              removeCartItem={removeCartProductItem}
                              setMyCart={setMyCart}
                            />
                          )
                      )}

                    {myCart?.products?.length > 0 &&
                      Object.keys(group_store).map(
                        (group, i) =>
                          group !== "undefined" && (
                            <StoreItems
                              key={i}
                              group={group}
                              group_store={group_store}
                              getTotalItemPrice={getTotalItemPrice}
                              removeCartItem={removeCartProductItem}
                              setMyCart={setMyCart}
                            />
                          )
                      )}

                    {myCart?.packages?.length > 0 && (
                      <div className="rounded-lg bg-white sm:max-w-3xl overflow-hidden border px-4 py-5 shadow-lg">
                        <div>
                          <h3 className="text-xl font-medium mb-6 pb-3 border-b">
                            Package Summary
                          </h3>
                          {myCart?.packages?.length > 0 &&
                            myCart?.packages.map((pack, i) => (
                              <PackageItems
                                key={i}
                                pack={pack}
                                removeCartItem={removeCartPackageItem}
                              />
                            ))}
                          <div className="border-t border-[#DADADA] mt-8">
                            <div className="justify-between flex w-full">
                              <button
                                className="text-lg text-primary-500 font-medium py-3 cursor-pointer"
                                onClick={() => navigate("/user/food-packages")}
                              >
                                + Add another package
                              </button>

                              <button
                                type="button"
                                className="text-lg text-primary-500 font-medium py-3 cursor-pointer"
                                onClick={() => clearCart()}
                              >
                                Clear Cart
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="mt-16 sm:max-w-3xl">
                      <h3 className="text-xl font-medium mb-2">
                        Add a coupon (optional)
                      </h3>
                      <p className="mb-4">
                        If you have a coupon code, please apply it below.
                      </p>
                      {myCart?.coupons?.length > 0 ||
                      myCart?.promotion_codes?.length > 0 ? (
                        <div className="flex gap-3">
                          <input
                            type={"text"}
                            placeholder="Enter coupon code"
                            name={"checkout_codes"}
                            value={
                              myCart?.coupons?.[0]?.coupon_slug ||
                              myCart?.promotion_codes?.[0]?.promotion_code_slug
                            }
                            disabled
                            className="mt-2 flex-1 h-12 pl-3 pr-10 w-full rounded-lg text-lg text-[#676565] bg-[#C4C4C4] focus:outline-none sm:text-sm px-4 py-2 mb-4 border border-[#DADADA] "
                          />
                          <button
                            type="button"
                            disabled={updatingCoupon}
                            className="justify-center h-12 rounded-md border border-primary shadow-sm px-4 py-3 mt-2 mb-4 bg-white text-primary font-medium sm:text-sm"
                            onClick={() => {
                              console.log("bleek");
                              removeCoupon();
                            }}
                          >
                            {updatingCoupon ? (
                              <Beat color={"#CE2600"} />
                            ) : (
                              "Remove Coupon"
                            )}
                          </button>
                        </div>
                      ) : (
                        <div className="flex gap-3">
                          <input
                            type={"text"}
                            placeholder="Enter coupon code"
                            name={"checkout_code_slug"}
                            onChange={(e) => handleChange(e)}
                            value={checkout_code_slug}
                            className="mt-2 flex-1 h-12 pl-3 pr-10 w-full rounded-lg text-base bg-[#FFFFFF] focus:ring focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm px-4 py-2 mb-4 border border-gray-300 "
                          />
                          <button
                            type="button"
                            disabled={updatingCoupon}
                            className="justify-center h-12 rounded-md border border-primary shadow-sm px-4 py-3 mt-2 mb-4 bg-primary text-white font-medium sm:text-sm"
                            onClick={() => applyCoupon()}
                          >
                            {updatingCoupon ? <Beat /> : "Apply Coupon"}
                          </button>
                        </div>
                      )}
                    </div>
                    {submitted && checkout_codes?.length < 1 && (
                      <p className="text-primary text-sm">
                        You have not entered any coupon code!
                      </p>
                    )}
                  </div>
                </section>
              </div>

              {/* Right column */}
              <Checkout myCart={myCart} currentAddress={currentAddress} />
            </div>
          </div>
        </main>
      </Container>
    </div>
  );
}
