import React from "react";

//icons
import { ReactComponent as LinkedIn } from "../../assets/images/icons/linkedin.svg";
import { ReactComponent as Instagram } from "../../assets/images/icons/instagram.svg";
import { ReactComponent as Facebook } from "../../assets/images/icons/facebook.svg";
import { ReactComponent as Twitter } from "../../assets/images/icons/twitter.svg";

export default function FooterCommon() {
  // const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  return (
    <div className="bg-[#080A0C]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-6 mt-24">
        <hr className="my-2 border-[#404040]" />

        <div className="py-4 sm:flex sm:justify-between">
          <p className="text-[#D8D8D8] mb-4 sm:mb-0">
            ©️ NotStore Limited 2024–{currentYear}
          </p>
          <div className="flex gap-4">
            <a
              href="t.me/thenotstorebot/thenotstorebot"
              target={"_blank"}
              rel="noreferrer"
              className="h-9 w-9 border border-[#404040] rounded-full flex items-center justify-center"
            >
              <LinkedIn style={{ fill: "#D8D8D8" }} />
            </a>

            <a
              href="t.me/thenotstorebot/thenotstorebot"
              target={"_blank"}
              rel="noreferrer"
              className="h-9 w-9 border border-[#404040] rounded-full flex items-center justify-center"
            >
              <Instagram style={{ fill: "#D8D8D8" }} />
            </a>

            <a
              href="t.me/thenotstorebot/thenotstorebot"
              target={"_blank"}
              rel="noreferrer"
              className="h-9 w-9 border border-[#404040] rounded-full flex items-center justify-center"
            >
              <Twitter style={{ fill: "#D8D8D8" }} />
            </a>

            <a
              target={"_blank"}
              rel="noreferrer"
              href="t.me/thenotstorebot/thenotstorebot"
              className="h-9 w-9 border border-[#404040] rounded-full flex items-center justify-center"
            >
              <Facebook style={{ fill: "#D8D8D8" }} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
