import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Menu, Popover, Transition } from "@headlessui/react";
// components
import { SignUpModal } from "../modals/auth/signUpModal";
import { ChooseAccountModal } from "../modals/auth/chooseAccountModal";
import NavBarMobile from "./NavBarMobile";
import PaymentSkeleton from "../skeleton/payment";
import { numberWithCommas } from "../helpers";
// actions
import { getUserFromCookies } from "../../utils/Auth";
import { isAuthenticatedUser } from "../../utils/Auth";
import { getTeamFromCookies } from "../../utils/Auth";
import { logoutUserLocallyAction } from "../../redux/auth/unAuthActions";
import { editCart, showMyCart } from "../../redux/cart/cartAction";
import { searchTheApplication } from "../../redux/scout/scoutActions";
// images
import { ReactComponent as MENU } from "../../assets/images/icons/menu.svg";
import { ReactComponent as SEARCH } from "../../assets/images/icons/search.svg";
// import { ReactComponent as SearchIcon } from "../../assets/images/icons/search.svg";
// import Logo from "../../assets/images/icons/notstore-logo.svg";
import HELP from "../../assets/images/icons/help.svg";
import ORDERS from "../../assets/images/icons/orders.svg";
import PROFILE from "../../assets/images/icons/profile.svg";
import CART1 from "../../assets/images/icons/groceries.svg";
import ACCOUNTS from "../../assets/images/icons/account-2.svg";
import CLOSE from "../../assets/images/icons/close.svg";
import { PIMAGE } from "../../assets/images/pictures/images";
import Image from "../../assets/images/emptyState/single-food.jpg";
import Placeholder from "../../assets/images/icons/scout-search.svg";

const userNavigation = [
  {
    name: "Profile",
    href: "/profile/info",
    image: <img src={PROFILE} alt="icon" />,
  },
  {
    name: "Orders",
    href: "/profile/orders",
    image: <img src={ORDERS} alt="icon" />,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBarCommon() {
  const isAuth = isAuthenticatedUser();
  const dispatch = useDispatch();
  const params = useParams();

  const { myCarts } = useSelector((state) => state.cart);
  const { searchApplication, searching } = useSelector((state) => state.scout);

  const user = getUserFromCookies();
  const team = getTeamFromCookies();
  let [isOpen, setIsOpen] = useState(false);
  let [openSearchModal, setOpenSearchModal] = useState(false);
  const [mobileSearchModal, setMobileSearchModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [cartTotal, setCartTotal] = useState(0);

  useEffect(() => {
    const localCart = JSON.parse(localStorage.getItem("notstore-cart"));
    if (isAuth) {
      dispatch(showMyCart());
      if (localCart) {
        dispatch(editCart({ user_id: user?.id, ...localCart }));
        localStorage.removeItem("notstore-cart");
        localStorage.removeItem("notstore-cart-items");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  function capitalize(word) {
    const words = word.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(" ");
  }

  function getInitials(word) {
    const words = word.split(" ");
    return words.map((word) => {
      return word[0].toUpperCase();
    });
  }

  const onImageError = (e) => {
    e.target.src = PIMAGE;
  };

  const onSearchImageError = (e) => {
    e.target.src = Image;
  };

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  function logoutUser() {
    dispatch(logoutUserLocallyAction());
  }

  function getCartTotal() {
    const localCart = JSON.parse(localStorage.getItem("notstore-cart"));
    if (isAuth && (myCarts?.products || myCarts?.packages)) {
      let product_no = myCarts?.products
        ? Number(myCarts?.products?.length)
        : 0;
      let package_no = myCarts?.packages
        ? Number(myCarts?.packages?.length)
        : 0;
      return product_no + package_no;
    } else {
      if (localCart?.products || localCart?.packages) {
        let product_local = localCart?.products
          ? Number(localCart?.products?.length)
          : 0;
        let package_local = localCart?.packages
          ? Number(localCart?.packages?.length)
          : 0;
        return product_local + package_local;
      } else {
        return 0;
      }
    }
  }

  useEffect(() => {
    const cartTotal = getCartTotal();
    setCartTotal(cartTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myCarts]);

  const handleSearchApplication = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    if (value.length >= 3) {
      setOpenSearchModal(true);
      dispatch(searchTheApplication({ search: value }));
    } else {
      setOpenSearchModal(false);
    }
  };

  return (
    <div className="Nav bar" data-test="navBarComponent">
      {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
      <Popover
        as="header"
        className={({ open }) =>
          classNames(
            open ? "fixed inset-0 z-40 overflow-y-auto" : "",
            "bg-white border-b border-gray-200 lg:static lg:overflow-y-visible"
          )
        }
      >
        {({ open }) => (
          <>
            <div className="py-4 max-w-7xl mx-auto px-4 sm:px-6 lg:px-6">
              {/* <div className="relative flex justify-between items-center xl:grid xl:grid-cols-12 lg:gap-8"> */}
              <div className="relative flex justify-between items-center lg:gap-8">
                <div className="flex lg:static xl:col-span-2">
                  <div className="flex-shrink-0 flex items-center">
                    {/* Mobile menu button */}
                    <Popover.Button className="lg:hidden mr-2 inline-flex items-center justify-center focus:outline-none">
                      <MENU className="h-6 w-6" />
                    </Popover.Button>
                    <a href="/user/home">
                      {/* <img
                        className="block h-8 w-auto"
                        src={Logo}
                        alt="NotStore"
                      /> */}
                      <span className="text-primary text-xl font-bold">
                        NOT STORE
                      </span>
                    </a>
                  </div>
                </div>

                <div className="hidden lg:block">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative">
                    <input
                      id="search"
                      name="search"
                      className="block w-[480px] bg-[#F7F7F7] border-none rounded-md py-2 px-4 placeholder-[#676565] focus:outline-none focus:ring-primary focus:border-primary focus:text-[#676565] focus:placeholder-gray-400 text-[16px]"
                      placeholder="Search"
                      type="search"
                      autoComplete="off"
                      autoFocus="off"
                      onChange={(e) => handleSearchApplication(e)}
                    />
                  </div>
                </div>

                <div className="flex py-4 items-center md:absolute md:right-0 md:inset-y-0 sm:hidden">
                  {/* Mobile menu button */}
                  {open && (
                    <Popover.Button className="-mx-2 rounded-md p-2 bg-[#FDF7F7] inline-flex items-center justify-center focus:outline-none">
                      <div>
                        <img
                          src={CLOSE}
                          className="block "
                          alt="ICON"
                          aria-hidden="true"
                        />
                      </div>
                    </Popover.Button>
                  )}
                </div>

                {!open ? (
                  <div className="flex items-center lg:justify-end xl:col-span-4">
                    <a href="/help" className="hidden sm:flex">
                      <img src={HELP} alt="help" className="h-5 w-5 mt-1" />
                      <p className=" text-lg font-normal ml-2">Help</p>
                    </a>
                    {/* Mobile Search button */}
                    <button
                      className="sm:hidden h-5 w-5"
                      onClick={() => setMobileSearchModal(!mobileSearchModal)}
                    >
                      <SEARCH />
                    </button>
                    {params["*"] && !params["*"].includes("store-checkout") && (
                      <a
                        href="/cart"
                        className="ml-4 sm:ml-5 flex bg-white relative"
                      >
                        <img
                          src={CART1}
                          className="h-5 w-5 mr-3 mt-1"
                          alt="cart"
                          aria-hidden="true"
                        />
                        <span className="hidden sm:block text-lg font-normal">
                          Cart
                        </span>

                        <span
                          className={`${
                            cartTotal && cartTotal > 0 ? "block" : "hidden"
                          } h-4 w-4 rounded-full bg-primary text-white absolute -top-2 left-3 text-center text-xs`}
                        >
                          {cartTotal}
                        </span>
                      </a>
                    )}
                    {isAuth && team !== null && (
                      <button
                        className="ml-4 sm:ml-5 flex bg-white"
                        onClick={openModal}
                      >
                        <img
                          src={ACCOUNTS}
                          className="h-5 w-5 mr-1 mt-1"
                          alt="accounts"
                          aria-hidden="true"
                        />
                        <span className="hidden sm:block text-lg font-normal">
                          Accounts
                        </span>
                      </button>
                    )}

                    {isAuth ? (
                      <Menu
                        as="div"
                        className="hidden sm:block flex-shrink-0 relative ml-5"
                      >
                        {/* Profile dropdown */}
                        <Menu.Button className="bg-white rounded-full flex focus:outline-none items-center">
                          {user?.picture ? (
                            <img
                              src={user?.picture}
                              onError={onImageError}
                              className="w-8 h-8 mr-1 rounded-full"
                              alt="user"
                            />
                          ) : (
                            <div className="w-[34px] h-[34px] rounded-full text-lg bg-gray-200 flex items-center justify-center mr-1">
                              {getInitials(user?.name)}
                            </div>
                          )}

                          <span className="text-lg truncate max-w-[135px]">
                            {capitalize(user?.name)}
                          </span>
                        </Menu.Button>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block py-2 px-4 text-sm text-gray-700"
                                    )}
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                            <button
                              className="block py-2 px-4 text-sm text-gray-700"
                              onClick={logoutUser}
                            >
                              Sign out
                            </button>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    ) : (
                      <button
                        className="ml-4 lg:ml-6 inline-flex items-center px-4 py-2 border border-transparent text-sm sm:text-lg font-medium rounded-md shadow-sm text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        onClick={openModal}
                      >
                        Log In
                      </button>
                    )}
                    {!isAuth && (
                      <SignUpModal show={isOpen} onClose={closeModal} />
                    )}
                    {isAuth && team !== null && (
                      <ChooseAccountModal show={isOpen} onClose={closeModal} />
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <NavBarMobile />
          </>
        )}
      </Popover>

      {/* Web search results modal opens when search input value length >= 3 */}
      {openSearchModal && (
        <div className="absolute left-0 right-0 w-full bg-white z-10 shadow shadow-lg max-w-[90%] sm:max-w-[1050px] mx-auto">
          {/* The search loader and details */}
          <div className="px-6 py-6">
            {searching ? (
              <PaymentSkeleton />
            ) : searchApplication?.data?.products?.length > 0 ||
              searchApplication?.data?.outlets?.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-10 sm:gap-8 gap-16">
                {/* Search outlets result */}
                <div className="sm:col-span-3">
                  <h5 className="text-[#91929D] mb-2">Outlets</h5>
                  {searchApplication?.data?.outlets?.length > 0 ? (
                    <ul className="grid grid-cols-1 gap-4 sm:max-h-[450px] overflow-scroll">
                      {searchApplication?.data?.outlets?.map((item, index) => (
                        <a
                          key={index}
                          href={
                            item?.store_name
                              ? `/store/${item?.id}`
                              : `/restaurant/${item?.id}`
                          }
                        >
                          <li className="flex gap-3 items-center cursor-pointer">
                            <img
                              src={
                                (item?.restaurant_pictures?.[0] ||
                                  item?.store_pictures?.[0]) ??
                                Image
                              }
                              className="h-[32px] w-[32px] object-cover rounded-[4px]"
                              alt="outlet"
                              onError={onSearchImageError}
                            />
                            <div>
                              <h4 className="capitalize">
                                {item?.restaurant_name || item?.store_name}
                              </h4>
                              <p className="uppercase text-xs">
                                <span>
                                  {item?.restaurant_name
                                    ? "Restaurant"
                                    : "Store"}
                                </span>
                                <span> {">"} </span>
                                <span>
                                  {item?.restaurant_city || item?.store_city}
                                </span>
                              </p>
                            </div>
                          </li>
                        </a>
                      ))}
                    </ul>
                  ) : (
                    <p>No outlets found</p>
                  )}
                </div>

                {/* Search products result */}
                <div className="sm:col-span-7">
                  <h5 className="text-[#91929D] mb-2">Products</h5>
                  {searchApplication?.data?.products?.length > 0 ? (
                    <ul className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6 sm:max-h-[450px] overflow-scroll">
                      {searchApplication?.data?.products?.map((item, index) => (
                        <a
                          key={index}
                          href={
                            item?.merchandise_name
                              ? `/store/${item?.store_id}`
                              : `/restaurant/${item?.restaurant_id}`
                          }
                        >
                          <li className="card cursor-pointer">
                            <img
                              src={
                                (item?.food_pictures?.[0] ||
                                  item?.merchandise_pictures?.[0]) ??
                                Image
                              }
                              className="h-[110px] w-full mb-2 object-cover rounded-[4px]"
                              alt="product"
                              onError={onSearchImageError}
                            />
                            <h5 className="uppercase text-xs mb-1">
                              {item?.food_name || item?.merchandise_name}
                            </h5>
                            <p className="mb-1 text-sm">
                              {item?.food_name || item?.merchandise_name}
                            </p>
                            <p className="font-bold text-[#333333]">
                              ₦
                              {numberWithCommas(item?.food_amount) ||
                                numberWithCommas(item?.merchandise_amount)}
                            </p>
                          </li>
                        </a>
                      ))}
                    </ul>
                  ) : (
                    <p>No products found</p>
                  )}
                </div>
              </div>
            ) : (
              <div className="p-4 max-w-[290px] mx-auto text-center">
                <img src={Placeholder} className="mx-auto my-8" alt="search" />
                <p className="text-[#23263B] font-bold tracking-sm mb-3">
                  Sorry, we didn’t find any matches for “{searchValue}”
                </p>
                <p className="text-[14px]">
                  Try different keywords and make sure all words are spelled
                  correctly!
                </p>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Web search results modal opens when search input value length >= 3 */}
      {mobileSearchModal && (
        <div className="absolute left-0 right-0 w-full bg-white z-10 shadow shadow-lg max-w-[90%] sm:max-w-[1050px] mx-auto">
          {/* The search input for mobile search */}
          <div className="block sm:hidden">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <input
                id="search"
                name="search"
                className="block w-full bg-[#F7F7F7] border-none rounded-xs py-3 px-4 placeholder-[#676565] focus:outline-none focus:ring-primary focus:border-primary focus:text-[#676565] focus:placeholder-gray-400 text-[16px]"
                placeholder="Search"
                type="search"
                autoComplete="off"
                defaultValue={searchValue}
                onChange={(e) => handleSearchApplication(e)}
              />
            </div>
          </div>
          {/* The search loader and details */}
          <div className="px-6 py-6">
            {searching ? (
              <PaymentSkeleton />
            ) : searchApplication?.data?.products?.length > 0 ||
              searchApplication?.data?.outlets?.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-10 sm:gap-8 gap-16">
                {/* Search outlets result */}
                <div className="sm:col-span-3">
                  <h5 className="text-[#91929D] mb-2">Outlets</h5>
                  {searchApplication?.data?.outlets?.length > 0 ? (
                    <ul className="grid grid-cols-1 gap-4 sm:max-h-[450px] overflow-scroll">
                      {searchApplication?.data?.outlets?.map((item, index) => (
                        <a
                          key={index}
                          href={
                            item?.store_name
                              ? `/store/${item?.id}`
                              : `/restaurant/${item?.id}`
                          }
                        >
                          <li className="flex gap-3 items-center cursor-pointer">
                            <img
                              src={
                                (item?.restaurant_pictures?.[0] ||
                                  item?.store_pictures?.[0]) ??
                                Image
                              }
                              className="h-[32px] w-[32px] object-cover rounded-[4px]"
                              alt="outlet"
                              onError={onSearchImageError}
                            />
                            <div>
                              <h4 className="capitalize">
                                {item?.restaurant_name || item?.store_name}
                              </h4>
                              <p className="uppercase text-xs">
                                <span>
                                  {item?.restaurant_name
                                    ? "Restaurant"
                                    : "Store"}
                                </span>
                                <span> {">"} </span>
                                <span>
                                  {item?.restaurant_city || item?.store_city}
                                </span>
                              </p>
                            </div>
                          </li>
                        </a>
                      ))}
                    </ul>
                  ) : (
                    <p>No outlets found</p>
                  )}
                </div>

                {/* Search products result */}
                <div className="sm:col-span-7">
                  <h5 className="text-[#91929D] mb-2">Products</h5>
                  {searchApplication?.data?.products?.length > 0 ? (
                    <ul className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6 sm:max-h-[450px] overflow-scroll">
                      {searchApplication?.data?.products?.map((item, index) => (
                        <a
                          key={index}
                          href={
                            item?.merchandise_name
                              ? `/store/${item?.store_id}`
                              : `/restaurant/${item?.restaurant_id}`
                          }
                        >
                          <li className="card cursor-pointer">
                            <img
                              src={
                                (item?.food_pictures?.[0] ||
                                  item?.merchandise_pictures?.[0]) ??
                                Image
                              }
                              className="h-[110px] w-full mb-2 object-cover rounded-[4px]"
                              alt="product"
                              onError={onSearchImageError}
                            />
                            <h5 className="uppercase text-xs mb-1">
                              {item?.food_name || item?.merchandise_name}
                            </h5>
                            <p className="mb-1 text-sm">
                              {item?.food_name || item?.merchandise_name}
                            </p>
                            <p className="font-bold text-[#333333]">
                              ₦
                              {numberWithCommas(item?.food_amount) ||
                                numberWithCommas(item?.merchandise_amount)}
                            </p>
                          </li>
                        </a>
                      ))}
                    </ul>
                  ) : (
                    <p>No products found</p>
                  )}
                </div>
              </div>
            ) : (
              <div className="p-4 max-w-[290px] mx-auto text-center">
                <img src={Placeholder} className="mx-auto my-8" alt="search" />
                <p className="text-[#23263B] font-bold tracking-sm mb-3">
                  Sorry, we didn’t find any matches for “{searchValue}”
                </p>
                <p className="text-[14px]">
                  Try different keywords and make sure all words are spelled
                  correctly!
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
