import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Input from "../../../../components/inputs/inputs";
import Button from "../../../../components/buttons/buttons";
import CreateCustomerModal from "../../../../components/modals/admin/createCustomerModal";
import AddOrderItemModal from "../../../../components/modals/admin/orders/admin/restaurant/addOrderItem";
import { cleanInput } from "../../../../helpers/cleanInput";
import Customer from "../../../../assets/images/icons/profile.svg";
// import Logo from "../../../../assets/images/icons/notstore-black-logo.svg";
import MINUS from "../../../../assets/images/icons/decreament.svg";
import PLUS from "../../../../assets/images/icons/increament.svg";
import { searchUsersAction } from "../../../../redux/users/userActions";
import { createRestaurantOrder } from "../../../../redux/restaurantOrder/restaurantOrderActions";
import authType from "../../../../redux/users/userTypes";

export default function CreateFoodPackageOrder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line
  // const { creating } = useSelector((state) => state.storeOrder);
  let { searchUser } = useSelector((state) => state.user);

  const [searchValue, setSearchValue] = useState({ search: "" });
  const [counter, setCounter] = useState(1);
  const [quantity, setQuantity] = useState({ quantity: counter });
  const [submitted, setSubmitted] = useState(false);
  const incrementCounter = () => {
    setCounter(counter + 1);
    setQuantity((counter) => ({
      quantity: counter + 1,
    }));
  };
  let decrementCounter = () => {
    setCounter(counter - 1);
    setQuantity((counter) => ({
      quantity: counter - 1,
    }));
  };
  const addQuantity = () => {
    if (quantity > 0) {
      order_type.push(quantity);
    }
  };
  // eslint-disable-next-line no-unused-vars
  const { search } = searchValue;
  if (counter <= 0) {
    decrementCounter = () => setCounter(1);
  }
  function minTwoDigits(n) {
    return (n < 10 ? "0" : "") + n;
  }
  let [recipient_phone, setRecipientPhone] = useState("");
  const [data, setData] = useState({
    business_id: "",
    user_address_id: "1",
    user_id: "",
    restaurant_id: "",
    recipient_name: "",
    recipient_email: "",
    recipient_phone: "",
    recipient_address: "",
    recipient_city: "",
    recipient_state: "",
    recipient_country: "",
    order_type: "",
    order_paid: "",
    order_payment_method: "",
    order_items: [],
    order_amount: "0",
  });
  const {
    // business_id,
    // restaurant_id,
    recipient_name,
    // eslint-disable-next-line
    // recipient_email,
    // recipient_country,
    // recipient_address,
    // recipient_city,
    // recipient_state,
    order_type,
  } = data;
  recipient_phone = recipient_phone?.slice(1);
  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setData({ ...data, [name]: value });
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setSearchValue((searchValue) => ({ ...searchValue, [name]: value }));
    if (value.length >= 3) {
      dispatch(
        searchUsersAction({
          search: value,
        })
      );
    } else if (value.length <= 3) {
      searchUser = { data: [] };
      dispatch({ type: authType["SEARCH_USERS"], payLoad: searchUser });
    }
  };

  function fillUser(result) {
    setData((data) => ({
      ...data,
      user_id: result.id ?? "",
      user_name: result.name ?? "",
      user_address_id: "1",
      recipient_name: result.name ?? "",
      recipient_phone: result.phone ?? "",
    }));
    var mydiv = document.getElementById(result.id);
    if (result.id) {
      mydiv.style.borderColor = "border-[#D14522]";
    }

    setSearchValue((searchValue) => ({
      search: result.recipient_name,
    }));

    searchUser = { data: [] };
    dispatch({ type: "SEARCH_USERS", searchUser });
  }

  // eslint-disable-next-line
  const handleSubmit = async () => {
    let newData = { ...data, recipient_phone: recipient_phone };
    cleanInput(newData?.order_items?.attributes);
    cleanInput(newData);
    setSubmitted(true);
    if (
      // business_id &&
      // restaurant_id &&
      // recipient_name &&
      // recipient_address &&
      // recipient_country &&
      // recipient_city &&
      // recipient_phone &&
      // recipient_state &&
      order_type
    ) {
      const response = await dispatch(
        // createRestaurantOrder({ ...data, recipient_phone: recipient_phone })
        createRestaurantOrder(newData)
      );
      if (response) {
        navigate("/admin/orders/restaurant/shipped/1");
      }
    }
  };

  return (
    <div data-test="createOrder-page">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="border border-[#E4E4F3] bg-white sm:h-[786px] rounded-md py-5 px-8">
          <div className="border-b border-[#E4E4F3] py-3 text-center">
            <h3 className="text-base text-gray-500 font-medium">
              Create New Order
            </h3>
          </div>

          <div className="text-right mt-4">
            <CreateCustomerModal
              handleChange={handleChange}
              data={data ? data : []}
              setData={setData}
              recipient_phone={recipient_phone}
              setRecipientPhone={setRecipientPhone}
            />
          </div>

          <form className="mt-2">
            <div className="mt-2 mb-3 relative">
              <input
                type={"search"}
                name={"recipient_name"}
                htmlFor={"recipient_name"}
                className={
                  "mt-2 block pl-3 pr-10 w-full text-base bg-gray-100 focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
                }
                placeholder={"Search user by name, phone Number, email..."}
                onChange={handleSearch}
                value={data?.user_name}
              />
              <img
                src={Customer}
                alt="customer"
                className=" fa-search absolute top-2 right-4"
              />
              {submitted && !recipient_name && (
                <div className="text-primary text-xs ">User is required</div>
              )}
            </div>

            {searchUser?.data &&
              Array.isArray(searchUser.data) &&
              searchUser?.data?.map((result, index) => (
                <div
                  className="p-3 border border-primary mb-2"
                  id={result.id}
                  key={index}
                  onClick={() => fillUser(result)}
                >
                  <div className=" grid-cols-3">
                    <div className="col-span-1">
                      <div className="">
                        <h6 className="text-sm font-meduim">
                          Name: {result.name}
                        </h6>
                      </div>
                    </div>
                    {/* <div className="col-span-1">
                      <div>
                        <h6 className="text-sm font-meduim">
                          Phone: {result.user_phone}
                        </h6>
                      </div>
                    </div> */}
                  </div>
                </div>
              ))}
            <div>
              {" "}
              <Input.Select
                title={"Select Order type"}
                name={"order_type"}
                htmlFor={"order_type"}
                onChange={handleChange}
              >
                <option>Select order type</option>
                <option value="standard">Standard</option>
                <option value="collect">Collect</option>
                <option value="subscription">Subscription</option>
              </Input.Select>
              {submitted && !order_type && (
                <div className="text-primary text-xs ">
                  order type is required
                </div>
              )}
            </div>

            <div>
              <Input.Number
                title={"Discount"}
                htmlFor={"order_discount"}
                name={"order_discount"}
                placeholder={"0"}
                onChange={handleChange}
              />
            </div>
            <Input.Select
              title={"Payment Method"}
              name={"order_payment_method"}
              onChange={handleChange}
              htmlFor={"order_payment_method"}
            >
              <option>Select payment Method</option>
              <option value={"Paystack"}>Paystack</option>
              <option value={"Transfer"}>Bank Transfer</option>
            </Input.Select>

            <Input.Select
              title={"Payment Status"}
              name={"order_paid"}
              onChange={handleChange}
              htmlFor={"order_paid"}
            >
              <option>Select payment status</option>
              <option value={1}>Paid</option>
              <option value={0}>Not Paid</option>
            </Input.Select>
          </form>
        </div>

        <div className="border border-[#E4E4F3] bg-white sm:h-[786px] sm:w-[527px] rounded-md py-5 px-8">
          <div className="flex justify-between items-start">
            {/* <img src={Logo} alt="black logo" className="mt-6" /> */}
            <span className="text-primary text-3xl font-bold mt-6">
              NOT STORE
            </span>
          </div>

          <div className="flex justify-between mt-5">
            <div>
              <p className="text-sm text-[#4D4D4D]">Payment Method</p>
              <p className="text-sm font-bold my-1">
                {data.order_payment_method}
              </p>
            </div>
            <div className="text-right">
              <p className="text-sm text-[#4D4D4D]">Payment Status</p>
              <p className="text-sm font-bold my-1">{data.order_paid}</p>
            </div>
          </div>
          <div className="flex justify-between my-8 ">
            <div>
              <p className="text-sm text-[#4D4D4D]">Recipients Name </p>
              <p className="text-sm font-bold my-1">{data?.recipient_name}</p>
            </div>
            <div className="text-right">
              <p className="text-sm text-[#4D4D4D]">Recipients Phone </p>
              <p className="text-sm font-bold my-1">{data?.recipient_phone}</p>
            </div>
          </div>
          <div className="border mt-10" />
          {data.order_items &&
            data.order_items?.length > 0 &&
            data.order_items?.map((item, index) => (
              <div
                key={index}
                className="px-6 py-2 border-b border-[#565656] border-dashed last:border-0 last:pb-20"
              >
                <div className="flex justify-between my-3">
                  <p className="text-sm font-bold ">{item.food_name}</p>
                  <p className="text-sm">{item.food_amount}</p>
                </div>
                {/* <div className="flex justify-between">
                    <p className="text-sm font-bold ">Abacha</p>
                    <p className="text-sm ">NGN 3,300 </p>
                  </div> */}
                <div
                  type="button"
                  className=" w-24 sm:w-[78px] inline-flex mt-4 items-center h-11 sm:h-5 text-base font-medium  sm:text-sm ml-2 sm:ml-0"
                >
                  <img
                    src={MINUS}
                    alt="decrement counter"
                    onClick={() => {
                      decrementCounter();
                      addQuantity();
                    }}
                    className=" w-6 h-6 sm:w-5 sm:h-5"
                  />

                  <p className=" mx-2 text-sm">{minTwoDigits(counter)}</p>
                  <img
                    src={PLUS}
                    alt="decrement counter"
                    onClick={incrementCounter}
                    className=" w-6 h-6 sm:w-5 sm:h-5"
                  />
                </div>
              </div>
            ))}
          <div className="border-b" />
          <AddOrderItemModal
            handleChange={handleChange}
            submitted={submitted}
            data={data}
            setData={setData}
          />

          <div className="mt-10">
            <div className="flex justify-between">
              <p className="text-sm font-bold py-1">Subtotal</p>
              <p className="text-sm font-bold py-1">NGN 00.00 </p>
            </div>
            <div className="flex justify-between">
              <p className="text-sm py-1">Discount</p>
              <p className="text-sm py-1"> 00.00</p>
            </div>
            <div className="flex justify-between">
              <p className="text-sm py-1">Delivery Fee</p>
              <p className="text-sm py-1 ">NGN 00.00</p>
            </div>
            <div className="flex justify-between">
              <p className="text-sm font-bold py-1">Total</p>
              <p className="text-sm font-bold py-1">NGN 00.00</p>
            </div>
          </div>

          <div className="border mt-2" />
        </div>
      </div>
      <div className="flex justify-center my-12">
        <Button.Primary
          title={"Create Order"}
          className="rounded-sm"
          type={"buutton"}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
