import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { cleanInput } from "../../../helpers/cleanInput";
//images
import CLOSE from "../../../assets/images/icons/close.svg";
import PIN from "../../../assets/images/icons/pin.svg";
//Api
import { Beat } from "../../../plugins/spinners.plugin";
import { AddNewAddressModal } from "./addNewAddressModal";
import {
  getMyAddressesAction,
  updateAnAddressAction,
  setDefaultAddress,
} from "../../../redux/address/addressActions";
import { isAuthenticatedUser } from "../../../utils/Auth";

export function AddressModal(props) {
  const dispatch = useDispatch();
  const isLoggedIn = isAuthenticatedUser();
  let [isOpen, setIsOpen] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [currentAddress, setCurrentAddress] = useState([]);
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const { myAddresses } = useSelector((state) => state.address);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getMyAddressesAction());
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  useEffect(() => {
    if (myAddresses?.data?.length > 0) {
      //filter to get the selected address
      var newAddressArray = myAddresses?.data?.filter(function (address) {
        return address?.address_selected !== true;
      });
      setAddresses(newAddressArray);
      //filter to get the selected address
      var newCurrentAddressArray = myAddresses?.data?.filter(function (
        address
      ) {
        return address.address_selected === true;
      });
      setCurrentAddress(newCurrentAddressArray);
      dispatch(setDefaultAddress(...newCurrentAddressArray));
    }
  }, [myAddresses?.data, dispatch]);

  const handleSubmit = async () => {
    let selected_id = selected?.id;

    cleanInput(selected);
    try {
      setLoading(true);
      const response = await dispatch(
        updateAnAddressAction({
          ...selected,
          address_selected: 1,
          id: selected_id,
        })
      );

      if (response?.status === "success") {
        setLoading(false);
        props.onClose();
        dispatch(getMyAddressesAction());
        window.location.reload(true);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div data-test="addressModal">
      <Transition show={props.show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-[600px] bg-white rounded-3xl px-4 pt-5 pb-4  overflow-hidden shadow-xl transform transition-all sm:my-10 sm:max-w-lg sm:w-full  sm:p-8">
                  <div className="sm:block absolute top-1 sm:top-3 right-4 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-[#ffeae5] rounded-3xl p-2 sm:p-3 outline-none"
                      onClick={props.onClose}
                    >
                      <span className="sr-only">Close</span>
                      <img
                        src={CLOSE}
                        alt="close"
                        className=" w-3 h-3 sm:w-4 sm:h-4"
                      />
                    </button>
                  </div>
                  <div className="sm:flex sm:justify-center">
                    <div className=" text-center sm:mt-2">
                      <Dialog.Title
                        as="h3"
                        className="text-xl font-medium text-black"
                      >
                        My address details
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4">
                    <div className="mt-4 border-b-2 "></div>
                    <div className="mt-8">
                      <ul>
                        {currentAddress?.length > 0 && (
                          <li className="bg-white border-b">
                            {currentAddress?.length > 0 &&
                              currentAddress?.map((current, i) => (
                                <div
                                  key={i}
                                  className="ml-3 flex justify-between items-center text-left mt-6 mb-4"
                                >
                                  <div>
                                    <label
                                      htmlFor={`address-radio-${i}`}
                                      className="flex items-start"
                                    >
                                      <img
                                        src={PIN}
                                        alt="location"
                                        className="mt-2"
                                      />
                                      <div className="ml-3">
                                        <p className="font-medium text-lg">
                                          {current?.title}
                                        </p>
                                        <p className=" text-sm text-gray-400 font-normal">
                                          {current?.address}, {current?.city},{" "}
                                          {current?.state}
                                        </p>
                                      </div>
                                    </label>
                                  </div>

                                  <input
                                    id={`address-radio-${i}`}
                                    name={`address-radio-${i}`}
                                    type={"radio"}
                                    checked={
                                      selected &&
                                      Object.keys(selected).length === 0
                                    }
                                    readOnly
                                    className="w-6 h-6 text-primary bg-white focus:ring-primary focus:ring-primary focus:ring-2"
                                  />
                                </div>
                              ))}
                          </li>
                        )}

                        {/* If there are multiple addresses, give room to change selected address */}
                        {addresses?.length > 0 && (
                          <div>
                            {currentAddress?.length > 0 && (
                              <li className="text-left sm:text-left mt-5 mb-3">
                                Change address to :
                              </li>
                            )}
                            {addresses?.length > 0 &&
                              addresses?.map((address, i) => (
                                <li
                                  className="bg-white border-b hover:bg-gray-100 group hover:shadow"
                                  key={i}
                                >
                                  <div className="ml-3 flex justify-between text-left items-center mt-6 mb-4">
                                    <label htmlFor={`address-radio-${i}`}>
                                      <div className="flex items-start">
                                        <img
                                          src={PIN}
                                          alt="location"
                                          className="mt-2"
                                        />
                                        <div className="ml-3">
                                          <p className="font-medium text-lg">
                                            {address?.title}
                                          </p>
                                          <p className="text-sm text-gray-400 font-normal">
                                            {address?.address}, {address?.city},{" "}
                                            {address?.state}
                                          </p>
                                        </div>
                                      </div>
                                    </label>

                                    <input
                                      id={`address-radio-${i}`}
                                      name="address-radio-group"
                                      type={"radio"}
                                      className="w-6 h-6 text-primary bg-white border-primary focus:ring-primary"
                                      onChange={() => setSelected(address)}
                                    />
                                  </div>
                                </li>
                              ))}
                          </div>
                        )}

                        <button
                          className="text-left mb-4 mt-4 text-primary w-full"
                          onClick={openModal}
                        >
                          + Add new address
                        </button>
                        <AddNewAddressModal
                          show={isOpen}
                          onClose={closeModal}
                        />

                        {addresses?.length > 0 && (
                          <button
                            type="button"
                            disabled={loading}
                            className="mt-5 mb-6 sm:mb-6 w-full inline-flex justify-center h-14 rounded-md border border-transparent shadow-sm px-4 py-4 bg-primary text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500  sm:mt-6 sm:text-sm"
                            onClick={handleSubmit}
                          >
                            {loading ? (
                              <Beat color={"#ffffff"} />
                            ) : (
                              "Confirm Address"
                            )}
                          </button>
                        )}
                      </ul>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
