import React from "react";
import Container from "../../components/container/container";
import ErrorBg from "../../assets/images/pictures/error404-bg.png";

export default function Error404() {
  return (
    <div className="bg-center bg-no-repeat bg-cover -mb-36" style={{ backgroundImage: `url(${ErrorBg})`, height: '100vh' }}>
       <Container>
        <div className="pt-12 sm:pt-24 justify-center items-center mx-auto pb-16">
          <div className="grid grid-cols-1 sm:grid-cols-2 items-center gap-10 sm:px-8">
            <div className="max-w-[620px]">
              <h3 className="text-9xl font-bold mb-2">Oops!</h3>
              <h4 className="text-5xl mb-5">We couldn’t find the page.</h4>
            </div>
          </div>
        </div>
      </Container>

    </div>
  );
}
