import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePaystackPayment } from "react-paystack";
import {
  getAllPaymentProviders,
  verifyPayment,
} from "../../../redux/payment/paymentActions";
import { isAuthenticatedUser } from "../../../utils/Auth";

export default React.forwardRef(function PaystackPlugin(
  { email, amount, onSuccess, onClose, reference, payment_type },
  ref
) {
  const dispatch = useDispatch();
  const { allProviders } = useSelector((state) => state.payment);
  const publicKey = allProviders?.pay_stack_key;
  const isLoggedIn = isAuthenticatedUser();

  useEffect(() => {
    dispatch(getAllPaymentProviders());
    // eslint-disable-next-line
  }, []);

  // Ref handler
  React.useImperativeHandle(ref, () => ({
    getAmount_Reference: (amount, ref) => getAmount_Reference(amount, ref),
  }));

  function empty() {
    /* empty because we have nothing to do... */
  }

  //verify only when logged in
  const verifyPaymentSuccess = () => {
    if (isLoggedIn) {
      dispatch(verifyPayment("paystack", { id: reference }));
    } else {
      empty();
    }
  };

  const [componentProps, setComponentProps] = useState({
    email: email ? email : "hello@notstore.com",
    amount,
    publicKey,
    reference,
    metadata: { payment_type: payment_type },
  });

  // on payment success
  const onSuccessFunction = () => {
    // Implementation for whatever you want to do with reference and after success call.
    setTimeout(onSuccess, 200);
    verifyPaymentSuccess();
  };

  // on modal close
  const onCloseFunction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    onClose();
    verifyPaymentSuccess();
  };

  const initializePayment = usePaystackPayment(componentProps);
  const startPayment = () => {
    initializePayment(onSuccessFunction, onCloseFunction);
  };

  const getAmount_Reference = (amount, ref) => {
    let new_amount = parseInt(amount * 100);
    setComponentProps({
      ...componentProps,
      amount: new_amount,
      reference: ref,
    });
  };

  useEffect(() => {
    startPayment();
    // eslint-disable-next-line
  }, [componentProps.amount]);
});
