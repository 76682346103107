import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import Logo from "../../../assets/images/icons/notstore-logo.svg";
import {
  getAllPaymentProviders,
  verifyPayment,
} from "../../../redux/payment/paymentActions";

const FlutterwavePlugin = React.forwardRef(
  (
    {
      email,
      amount,
      children,
      phone_number,
      name,
      tx_ref,
      onClose,
      payment_type,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const { allProviders } = useSelector((state) => state.payment);
    const publicKey = allProviders?.git ;

    useEffect(() => {
      dispatch(getAllPaymentProviders());
      // eslint-disable-next-line
    }, []);

    const verifyPaymentSuccess = () => {
      dispatch(verifyPayment("flutterwave", { id: tx_ref }));
    };

    const [config, setConfig] = useState({
      public_key: publicKey,
      tx_ref: tx_ref,
      amount: Number(amount),
      currency: "NGN",
      payment_options: "card,mobilemoney,ussd",
      customer: {
        email: email,
        phone_number: phone_number,
        name: name,
      },
      meta: { payment_type: payment_type },
      customizations: {
        title: "Pay NotStore Limited",
        description: "Pending payments",
        logo: Logo,
      },
    });

    const handleFlutterPayment = useFlutterwave(config);

    // Ref handler
    React.useImperativeHandle(ref, () => ({
      getAmount_Reference: (amount, ref) => getAmount_Reference(amount, ref),
    }));

    const getAmount_Reference = (amount, ref) => {
      setConfig({
        ...config,
        amount: amount,
        tx_ref: ref,
      });
      handleFlutterPayment({
        callback: (response) => {
          verifyPaymentSuccess();
          closePaymentModal(); // this will close the modal programmatically
          onClose(); //close modal and redirect to orders page
        },
        onClose: () => {
          //close modal and redirect to orders page
          verifyPaymentSuccess();
          onClose();
        },
      });
    };
  }
);

export default FlutterwavePlugin;
