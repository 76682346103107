import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Shipped from "./tabs/Shipped";
import Unshipped from "./tabs/Unshipped";
import Delivered from "./tabs/Delivered";
import Closed from "./tabs/Closed";

const tabs = [
  { name: "Shipped", href: "shipped" },
  { name: "Unshipped", href: "unshipped" },
  { name: "Delivered", href: "delivered" },
  { name: "Closed", href: "closed" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function RestaurantOrders() {
  const navigate = useNavigate();
  const DEFAULT_ACTIVE_TAB = "shipped";
  const { active_tab } = useParams();

  useEffect(() => {
    if (!active_tab) {
      navigate(`/admin/orders/restaurant/${DEFAULT_ACTIVE_TAB}/1`);
    }
  });

  const toggle = (tab) => {
    if (active_tab !== tab) {
      navigate(`/admin/orders/restaurant/${tab.href}/1`);
    }
  };

  return (
    <div data-test="restaurant-orders-page">
      {/* <div className="mb-10">
        <h1 className="text-3xl font-semibold text-gray-900">
          Restaurant Orders
        </h1>
      </div> */}

      <div className="sm:block">
        <div className="border-b border-gray-200">
          <nav
            className="-mb-px flex space-x-8 overflow-x-auto"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => {
                  toggle(tab);
                }}
                className={classNames(
                  tab.href === active_tab
                    ? "border-primary text-primary font-bold"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.href === active_tab ? "page" : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>

      {active_tab === "shipped" && <Shipped />}
      {active_tab === "unshipped" && <Unshipped />}
      {active_tab === "delivered" && <Delivered />}
      {active_tab === "closed" && <Closed />}
    </div>
  );
}
