import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import Button from "../../../../components/buttons/buttons";
import { copyTextToClipboard } from "../../../../components/helpers";
import { ReactComponent as CopyIcon } from "../../../../assets/images/icons/copy-outline.svg";
import { Assignshipping } from "../../../../components/modals/assignShipper";
import { CloseRestaurantOrder } from "../../../../components/modals/admin/orders/closeRestaurantOrder";
import { getARestaurantOrderAction } from "../../../../redux/restaurantOrder/restaurantOrderActions";
import { getAUserAction } from "../../../../redux/users/userActions";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function SingleRestaurantOrder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { order } = useSelector((state) => state.restaurantOrder);

  useEffect(() => {
    if (params?.id) {
      dispatch(getARestaurantOrderAction(params?.id));
    }
    // eslint-disable-next-line
  }, [params?.id]);

  useEffect(() => {
    if (order?.created_by) {
      dispatch(getAUserAction(order?.created_by));
    }
    // eslint-disable-next-line
  }, [order?.created_by]);

  return (
    <div data-test="singleOrder-page">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 pt-6">
        {/* Grid 1 */}
        <div>
          <div className="border border-[#E4E4F3] bg-white rounded-md px-4 sm:px-8 py-5">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Order Details</h3>
              {(order?.status === "unshipped" ||
                order?.status === "shipped") && (
                <Button.Primary
                  type={"button"}
                  title={"Edit Order"}
                  onClick={() => {
                    navigate(`/admin/orders/restaurant/edit/${order?.id}`);
                  }}
                />
              )}
            </div>

            <div className="grid grid-cols-2 gap-4 sm:gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order date
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  {moment(order?.created_at).format("MMM DD, YYYY")}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Order ID</label>
                <p className="text-[#151515] text-sm font-bold flex">
                  <span className="flex items-center">
                    #
                    {order?.id?.substr(0, 7) +
                      "...." +
                      order?.id?.substr(order?.id?.length - 6)}
                    <button
                      type="button"
                      onClick={() => {
                        copyTextToClipboard(order?.id);
                      }}
                    >
                      <CopyIcon className="h-4 w-4 ml-1" />
                    </button>
                  </span>
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order Amount
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  NGN {numberWithCommas(order?.order_sub_amount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Delivery Fee
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  NGN {numberWithCommas(order?.order_delivery_fee)}
                </p>
              </div>

              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order Discount
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  NGN {numberWithCommas(order?.order_discount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Insurance Fee
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  NGN {numberWithCommas(order?.order_insurance_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Service Fee
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  NGN {numberWithCommas(order?.order_service_fee)}
                </p>
              </div>

              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Total Amount
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  NGN {numberWithCommas(order?.order_amount)}
                </p>
              </div>

              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order Status
                </label>
                <div className="text-sm font-bold">
                  {order?.status === "unshipped" ? (
                    <p className="text-primary text-sm font-bold">Unshipped</p>
                  ) : order?.status === "shipped" ? (
                    <p className="text-[#046ECF] text-sm font-bold">Shipped</p>
                  ) : order?.status === "closed" ? (
                    <p className="text-primary text-sm font-bold">Closed</p>
                  ) : order?.status === "delivered" ? (
                    <p className="text-[#00D220] text-sm font-bold">
                      Delivered
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Payment Status
                </label>
                {order?.order_paid ? (
                  <p className="text-[#00D220] text-sm font-bold">Paid</p>
                ) : (
                  <p className="text-primary text-sm font-bold">Unpaid</p>
                )}
              </div>
              {order?.order_payment_method !== null && (
                <div>
                  <label className="text-[#9CA3AF] text-sm mb-2">
                    Payment Method
                  </label>
                  <p className="text-[#1634A1] text-sm font-bold">
                    {order?.order_payment_method}
                  </p>
                </div>
              )}
            </div>
            <div className="grid grid-cols-2 gap-4 sm:gap-8 mt-8">
              {order?.status === "unshipped" && (
                <CloseRestaurantOrder
                  recipient={order?.recipient_name}
                  id={order?.id}
                />
              )}
              {order?.status === "unshipped" && (
                <Assignshipping
                  order_id={order?.id}
                  order_type={"restaurant"}
                />
              )}
            </div>
          </div>

          {order?.order_coupons?.length > 0 && (
            <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-6">
              <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
                <h3 className="text-lg font-medium">Coupon Details</h3>
              </div>

              {order?.order_coupons?.map((coupon, index) => (
                <div
                  key={index}
                  className="grid grid-cols-2 gap-4 sm:gap-8 mt-6"
                >
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">
                      Slug Name
                    </label>
                    <p className="text-[#151515] text-sm font-bold">
                      {coupon?.slug}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">Title</label>
                    <p className="text-[#151515] text-sm font-bold flex">
                      {coupon?.title}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">
                      Amount
                    </label>
                    <p className="text-[#151515] text-sm font-bold">
                      NGN {numberWithCommas(coupon?.amount)}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">
                      Expires
                    </label>
                    <p className="text-[#151515] text-sm font-bold">
                      {moment(coupon?.expiry).format("MMM DD, YYYY")}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {order?.order_promotion_codes?.length > 0 && (
            <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-6">
              <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
                <h3 className="text-lg font-medium">Promo Code Details</h3>
              </div>

              {order?.order_promotion_codes?.map((coupon, index) => (
                <div
                  key={index}
                  className="grid grid-cols-2 gap-4 sm:gap-8 mt-6"
                >
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">
                      Slug Name
                    </label>
                    <p className="text-[#151515] text-sm font-bold">
                      {coupon?.slug}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">Title</label>
                    <p className="text-[#151515] text-sm font-bold flex">
                      {coupon?.title}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">
                      Amount
                    </label>
                    <p className="text-[#151515] text-sm font-bold">
                      NGN {numberWithCommas(coupon?.amount)}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">
                      Expires
                    </label>
                    <p className="text-[#151515] text-sm font-bold">
                      {moment(coupon?.expiry).format("MMM DD, YYYY")}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Grid 2 */}
        <div>
          <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-4 sm:px-8">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Restaurant Details</h3>
            </div>

            <div className="grid grid-cols-2 gap-4 sm:gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Restaurant Name
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  {order?.restaurant?.restaurant_name}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Restaurant Email
                </label>
                <p className="text-[#151515] text-sm font-bold break-words">
                  {order?.restaurant?.restaurant_email}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Restaurant Phone Number
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  {order?.restaurant?.restaurant_phone}
                </p>
              </div>
            </div>
          </div>

          <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-4 sm:px-8 mt-6">
            <div className="border-b border-[#E4E4F3] py-3 mb-4 flex justify-between items-center">
              <h3 className="text-lg font-medium">
                Product Details (Optional)
              </h3>
            </div>

            {order?.order_items?.length > 0 &&
              order?.order_items?.map((product, i) => (
                <div key={i}>
                  <div className="flex justify-between items-start mb-5">
                    <div className="flex">
                      <span className="w-6 mr-3 sm:mr-10">
                        {product.quantity}x
                      </span>
                      <div>
                        <p className="mb-1">{product?.product_name}</p>
                        {product?.product_attributes?.length > 0 &&
                          product?.product_attributes?.map((attr, i) => (
                            <p key={i} className="text-sm text-[#676565]">
                              {attr.quantity}x {attr?.name} - NGN {attr.amount}
                            </p>
                          ))}
                      </div>
                    </div>
                    <p>NGN {product?.product_amount}</p>
                  </div>
                </div>
              ))}
          </div>

          <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-4 sm:px-8 mt-6">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Customer Details</h3>
            </div>

            <div className="grid grid-cols-2 gap-4 sm:gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Name</label>
                <p className="text-[#151515] text-sm font-bold">
                  {order?.recipient_name}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
                <p className="text-[#151515] text-sm  font-bold">
                  {order?.recipient_email}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Phone Number
                </label>
                <p className="text-[#151515] text-sm  font-bold">
                  {order?.recipient_phone}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Address</label>
                <p className="text-[#151515] text-sm font-bold">
                  {order?.recipient_address}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
