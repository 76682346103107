import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { copyTextToClipboard } from "../../helpers";
import Copy from "../../../assets/images/icons/copy.svg";
import CLOSE from "../../../assets/images/icons/close.svg";
// import LOGO from "../../../assets/images/icons/notstore-o.svg";
import FLUTTER from "../../../assets/images/icons/flutter.svg";
import PAYSTACK from "../../../assets/images/icons/paystack.svg";
import TRANSFER from "../../../assets/images/icons/bank-transfer.svg";
import Warning from "../../../assets/images/icons/warning-circle.svg";
import PaymentSkeleton from "../../skeleton/payment";
import PaystackPlugin from "../../../pages/common/payments/PaystackOnClick";
import PaystackTransferPlugin from "../../../pages/common/payments/PaystackBankTransfer";
import FlutterwavePlugin from "../../../pages/common/payments/FlutterwaveOnClick";
import { getUserFromCookies } from "../../../utils/Auth";

export default React.forwardRef(function RetryPaymentModal(
  {
    visibility = false,
    primaryButtonTitle,
    primaryButton,
    primaryButtonStyle,
    primaryButtonClassName,
    modalTitle,
    modalTitleSection,
    modelContent,
    modelContentSection,
    disableClose = false,
    disableOpen = false,
    onOpen = () => {},
    onClose = () => {},
  },
  ref
) {
  // Ref handler
  React.useImperativeHandle(ref, () => ({
    open: () => openModal(),
    close: () => closeModal(),
    getPfm: (data) => getPfmNumber(data),
    getTotalPrice: (data) => getTotalPrice(data),
    getReference: (data) => getReferenceId(data),
  }));

  const navigate = useNavigate();
  const user = getUserFromCookies();
  const paystackRef = useRef(null);
  const paystackTransferRef = useRef(null);
  const flutterRef = useRef(null);
  let [isOpen, setIsOpen] = React.useState(visibility);
  let [pfm, setPfm] = React.useState("");
  let [totalPrice, setTotalPrice] = React.useState("");
  let [reference_id, setReferenceId] = React.useState("");

  const { settings, isLoading } = useSelector((state) => state.application);

  function closeModal() {
    !disableClose && setIsOpen(false);
    !disableClose && onClose();
  }

  function openModal() {
    !disableOpen && setIsOpen(true);
    !disableOpen && onOpen();
  }

  function getPfmNumber(data) {
    let base_url = window.location.origin;
    let text = `${base_url}/pay-for-me/${data}`;
    setPfm(text);
  }

  function getTotalPrice(data) {
    setTotalPrice(data);
  }

  function getReferenceId(data) {
    setReferenceId(data);
  }

  function goToBank() {
    navigate(`/bank-transfer`, {
      state: { totalPrice: totalPrice },
    });
  }

  function goToOrders() {
    navigate(`/profile/orders`);
  }

  function goToPayment() {
    navigate(`/profile/payments`);
  }

  return (
    <React.Fragment>
      <div className="">
        {primaryButton ? (
          <span className="cursor-pointer" onClick={openModal}>
            {primaryButton}
          </span>
        ) : (
          <button
            type="button"
            style={primaryButtonStyle}
            onClick={openModal}
            disabled={primaryButtonStyle?.disabled}
            className={
              "glade-normal-text-three glade-button-blue whitespace-nowrap w-11/12 py-2 px-4 flex justify-center items-center text-white text-center rounded-sm " +
              primaryButtonClassName
            }
          >
            {primaryButtonTitle ?? "Title"}
          </button>
        )}
      </div>

      <Transition appear show={visibility || isOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay
                className="fixed inset-0"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
              />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="relative w-[629px] bg-white rounded-sm px-4 pt-4 pb-4 overflow-hidden shadow-xl transform transition-all sm:my-10 sm:max-w-lg sm:w-full sm:px-8 sm:py-6">
                    <div className="sm:block absolute top-2 right-3 pt-4 pr-4">
                      <button type="button" className="" onClick={closeModal}>
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>

                    <div className="sm:flex sm:items-start">
                      <div className="text-center mx-auto pb-3">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-medium text-black"
                        >
                          How would you like to pay?
                        </Dialog.Title>
                      </div>
                    </div>
                    <div className="border-b-2"></div>
                    <div className="mt-6 flex gap-2 items-center justify-center">
                      <img
                        src={Warning}
                        alt="warning"
                        className="w-[18px] h-[18px] cursor-pointer"
                      />
                      <p className="text-md">
                        "If your card hasn't been enabled for online
                        transactions, kindly opt for a bank transfer as an
                        alternative."
                      </p>
                    </div>
                    <div className="mt-3 sm:mt-2">
                      <div className="mt-5">
                        {isLoading ? (
                          <PaymentSkeleton />
                        ) : (
                          <ul>
                            {settings?.notstore_payment_status ===
                              "available" && (
                              <li
                                className="flex mb-4 bg-white h-22 shadow py-4 text-left rounded-sm cursor-pointer hover:bg-gray-100 group hover:shadow"
                                onClick={goToBank}
                              >
                                {/* <img
                                  src={LOGO}
                                  alt="NotStore o logo"
                                  className="bg-gray-100 p-2 ml-3 w-12 h-12 rounded-lg "
                                /> */}
                                <span className="text-primary text-xl font-bold">
                                  NOT STORE
                                </span>
                                <div className="ml-3 overflow-hidden ">
                                  <p className=" text-lg font-meduim text-black ">
                                    NotStore Pay
                                  </p>
                                  <p className=" text-base text-[#676565] font-normal">
                                    Transfer into NotStore account
                                  </p>
                                </div>
                              </li>
                            )}
                            {settings?.pay_stack_payment_status ===
                              "available" && (
                              <li
                                className="flex mb-4 bg-white h-20 shadow py-4 text-left rounded-sm cursor-pointer hover:bg-gray-100 group hover:shadow"
                                onClick={() =>
                                  paystackTransferRef.current.getAmount_Reference(
                                    totalPrice,
                                    reference_id
                                  )
                                }
                              >
                                <img
                                  src={TRANSFER}
                                  alt="logo"
                                  className="bg-gray-100 p-2 ml-3 w-12 h-12 rounded-lg "
                                />
                                <div className="ml-3 overflow-hidden ">
                                  <p className=" text-lg font-meduim text-black ">
                                    Bank Transfer
                                  </p>
                                  <p className=" text-base text-[#676565] font-normal">
                                    Transfer into NotStore account
                                  </p>
                                </div>
                              </li>
                            )}
                            <PaystackTransferPlugin
                              amount={totalPrice}
                              email={user?.email}
                              reference={reference_id}
                              payment_type={"standard"}
                              onClose={goToPayment}
                              onSuccess={goToOrders}
                              ref={paystackTransferRef}
                            />
                            {settings?.pay_stack_payment_status ===
                              "available" && (
                              <li
                                onClick={() =>
                                  paystackRef.current.getAmount_Reference(
                                    totalPrice,
                                    reference_id
                                  )
                                }
                                className="flex w-full mb-4 bg-white h-22 shadow rounded-sm py-4 text-left cursor-pointer hover:bg-gray-100 group hover:shadow"
                              >
                                <img
                                  src={PAYSTACK}
                                  alt="payStack logo"
                                  className="bg-gray-100 p-4 ml-3 w-12 h-12 rounded-lg "
                                />
                                <div className="ml-3 overflow-hidden ">
                                  <p className="text-lg font-meduim text-black ">
                                    Paystack
                                  </p>
                                  <p className=" text-base text-[#676565] font-normal">
                                    Pay with Card, USSD, Bank Transfer or Visa
                                    QR
                                  </p>
                                </div>
                              </li>
                            )}

                            <PaystackPlugin
                              amount={totalPrice}
                              email={user?.email}
                              reference={reference_id}
                              payment_type={"standard"}
                              onClose={goToPayment}
                              onSuccess={goToOrders}
                              ref={paystackRef}
                            />

                            {settings?.flutter_wave_payment_status ===
                              "available" && (
                              <li
                                onClick={() => {
                                  flutterRef.current.getAmount_Reference(
                                    totalPrice,
                                    reference_id
                                  );
                                }}
                                className="flex mb-4 bg-white h-20  shadow-lg py-4 text-left rounded-sm cursor-pointer hover:bg-gray-100 group hover:shadow"
                              >
                                <img
                                  src={FLUTTER}
                                  alt="NotStore logo"
                                  className="bg-gray-100 p-2 ml-3 w-12 h-12 rounded-lg "
                                />
                                <div className="ml-3 overflow-hidden">
                                  <p className="text-xl font-meduim text-black">
                                    Flutterwave
                                  </p>
                                  <p className=" text-base text-[#676565] font-normal ">
                                    Pay with Card, USSD, Bank Transfer.
                                  </p>
                                </div>
                              </li>
                            )}

                            <FlutterwavePlugin
                              amount={totalPrice}
                              email={user?.email}
                              phone_number={user?.phone}
                              name={user?.name}
                              payment_type={"standard"}
                              tx_ref={reference_id}
                              onClose={goToPayment}
                              onSuccess={goToOrders}
                              ref={flutterRef}
                            />

                            <li className="flex mb-6 bg-white h-22 shadow-lg py-4 text-left rounded-sm cursor-pointer hover:bg-gray-100 group hover:shadow">
                              <div className="px-3 overflow-hidden">
                                <p className="text-xl font-meduim text-black">
                                  Pay For Me
                                </p>

                                <div className="flex gap-4 w-full items-center justify-between">
                                  <span className=" text-sm text-[#676565] font-normal truncate">
                                    {pfm}
                                  </span>
                                  <img
                                    className="w-7 h-7"
                                    src={Copy}
                                    alt="copy-icon"
                                    onClick={() => copyTextToClipboard(pfm)}
                                  />
                                </div>
                              </div>
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
});
