import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//components
import CategorySlider, {
  CategoryNav,
} from "../../../components/slider/CategorySlider";
import Container from "../../../components/container/container";
import Card from "../../../components/cards/cards";
import RestaurantSkeleton from "../../../components/skeleton/restaurants";
import CategoriesSkeleton from "../../../components/skeleton/categories";
import { FilterModal } from "../../../components/modals/filterModal";
import FilterRestaurants from "../filter/Restaurant";
import { ReactComponent as Filter } from "../../../assets/images/icons/filter.svg";
import EmptyState from "../../../assets/images/emptyState/restaurant.svg";
import { cleanInput } from "../../../helpers/cleanInput";
import { getOutletCategories } from "../../../redux/productCatergory/productCategoryAction";
import {
  getAllPublicRestaurantsAction,
  getMorePublicRestaurantsAction,
} from "../../../redux/publicRestaurant/publicRestaurantActions";
import {
  filterPublicRestaurantsAction,
  filterMorePublicRestaurantsAction,
} from "../../../redux/publicRestaurant/publicRestaurantActions";
import { showApplication } from "../../../redux/applicationSettings/applicationAction";
import Button from "../../../components/buttons/buttons";
import { Beat } from "../../../plugins/spinners.plugin";

export default function RestaurantList() {
  const categoryRef = useRef(null);
  const dispatch = useDispatch();
  const user_city = localStorage.getItem("notstore-user-city");

  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [filterCount, setFilterCount] = useState(0);

  const { allCategories, loading } = useSelector(
    (state) => state.productCatergory
  );
  const { allRestaurants, isLoading, loadingMore } = useSelector(
    (state) => state.publicRestaurant
  );
  const { settings } = useSelector((state) => state.application);
  let restaurants = allRestaurants?.data;

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function getRestaurantCategories() {
    let items = allCategories?.data?.filter(
      (item) => item.type === "restaurant"
    );
    setCategories(items);
  }

  useEffect(() => {
    dispatch(showApplication());
    // dispatch(getAllPublicRestaurantsAction(page));
    dispatch(getOutletCategories(1));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user_city !== null) {
      dispatch(
        filterPublicRestaurantsAction({ restaurant_city: user_city }, page)
      );
    } else {
      dispatch(getAllPublicRestaurantsAction(page));
    }
    //eslint-disable-next-line
  }, [user_city]);

  useEffect(() => {
    getRestaurantCategories();
    // eslint-disable-next-line
  }, [allCategories]);

  const handleFilter = (data) => {
    cleanInput(data);
    dispatch(filterPublicRestaurantsAction(data));
  };

  const loadMore = async () => {
    let newPage = Number(page) + 1;
    setPage(newPage);
    if (user_city !== null) {
      await dispatch(
        filterMorePublicRestaurantsAction(
          { restaurant_city: user_city },
          newPage
        )
      );
    } else {
      await dispatch(getMorePublicRestaurantsAction(newPage));
    }
  };

  return (
    <div className="ResturantList" data-test="restaurantList">
      <FilterModal
        categories={categories}
        show={isOpen}
        onClose={closeModal}
        onClick={handleFilter}
        setFilterCount={setFilterCount}
      />
      <Container>
        <div className="py-5 mt-12">
          <div className="flex gap-4 justify-between items-center mb-4">
            <h3 className="text-3xl lg:text-4xl font-bold">Restaurants</h3>
            <div className="flex items-center gap-4">
              <span className="hidden sm:block">
                Sort by: <span className="text-primary">All</span>
              </span>
              <div className="w-[40px] h-[40px] bg-[#FFEAE5] rounded items-center justify-center flex relative">
                <span
                  className={`${
                    filterCount > 0 ? "block" : "hidden"
                  } h-4 w-4 rounded-full bg-black text-white absolute -top-1 -right-2 text-center text-xs`}
                >
                  {filterCount}
                </span>
                <Filter
                  className="cursor-pointer h-6 w-6"
                  onClick={openModal}
                />
              </div>
            </div>
          </div>
          {loading && <CategoriesSkeleton />}
          {!loading && filterCount === 0 && categories?.length > 0 && (
            <>
              <div className="flex justify-between items-center mb-6 mt-16">
                <h3 className="text-2xl font-bold">Categories</h3>
                {categories?.length > 6 && (
                  <div className="flex items-center gap-4">
                    <CategoryNav categoryRef={categoryRef} />
                  </div>
                )}
              </div>
              <CategorySlider categoryRef={categoryRef}>
                {categories?.map((category, index) => (
                  <Card.Categories key={index} category={category} />
                ))}
              </CategorySlider>
            </>
          )}
        </div>
      </Container>

      <Container>
        <div className="py-5 sm:mt-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-2xl font-bold">All Restaurants</h3>
          </div>
          {isLoading && <RestaurantSkeleton />}
          {!isLoading && (
            <>
              {filterCount === 0 ? (
                <div className="quotes">
                  {restaurants?.length > 0 ? (
                    <>
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                        {restaurants?.map((restaurant, i) => (
                          <Card.Restaurants
                            key={i}
                            id={restaurant?.id}
                            title={restaurant?.restaurant_name}
                            tags={restaurant?.restaurant_tags}
                            categories={restaurant?.restaurant_categories}
                            image={restaurant?.restaurant_pictures?.[0]}
                            operating_settings={
                              settings?.restaurant_operating_status
                            }
                            operating_status={
                              restaurant?.restaurant_operating_status
                            }
                            operating_hours={
                              restaurant?.restaurant_operating_time
                            }
                          />
                        ))}
                      </div>

                      <div className="mt-24 mb-10 text-center">
                        <p className="mb-4">
                          You’ve viewed{" "}
                          <span className="font-bold">
                            {allRestaurants?.data?.length}
                          </span>{" "}
                          out of {allRestaurants?.total} results
                        </p>
                        {allRestaurants?.data?.length <
                          allRestaurants?.total && (
                          <Button.Secondary
                            title={loadingMore ? <Beat /> : "Load More"}
                            disabled={loadingMore}
                            className="px-16"
                            onClick={() => loadMore()}
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="flex justify-center py-16">
                      <div className="text-center">
                        <img src={EmptyState} alt="empty" />
                        <p className="my-6">No restaurants found</p>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <FilterRestaurants />
              )}
            </>
          )}
        </div>
      </Container>
    </div>
  );
}
