import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { promoCodeService } from "../../services/promoCode.service";
import promoType from "./promoTypes";

/**
 * GetAllCoupons
 *
 */
export const getAllPromoCodes = (page) => async (dispatch) => {
  try {
    dispatch({
      type: promoType["LOADING_PROMO"],
      payLoad: true,
    });
    const response = await promoCodeService.getAllCoupons(page);
    dispatch({
      type: promoType["LOADING_PROMO"],
      payLoad: false,
    });
    dispatch({
      type: promoType["GET_ALL_PROMO"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: promoType["LOADING_PROMO"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * StoreCoupons
 * @param {object} payLoad{ name:"gaming",types: skilled}
 * @returns {void}
 * */
export const createPromoCode = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: promoType["LOADING_PROMO"],
      payLoad: true,
    });
    const response = await promoCodeService.storePromoCode({
      ...payLoad,
    });
    dispatch({
      type: promoType["LOADING_PROMO"],
      payLoad: false,
    });
    dispatch({
      type: promoType["STORE_PROMO"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: promoType["LOADING_PROMO"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Delete PromoCode
 * @param {object} payLoad{ id:"22"}
 * @returns {void}
 * */
export const deletePromoCode = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: promoType["DELETING"],
      payLoad: true,
    });
    const response = await promoCodeService.deletePromoCode({
      id: payLoad,
    });
    dispatch({
      type: promoType["DELETING"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: promoType["DELETING"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Expire PromoCode
 * @param {object} payLoad{ id:"22"}
 * @returns {void}
 * */
export const expirePromoCode = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: promoType["DELETING"],
      payLoad: true,
    });
    const response = await promoCodeService.expirePromoCode({
      id: payLoad,
    });
    dispatch({
      type: promoType["DELETING"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: promoType["DELETING"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Edit Coupon
 * @param {object} payLoad {id:"22"}
 * @returns {void}
 * */
export const editCoupon = (payLoad) => async (dispatch) => {
  try {
    const response = await promoCodeService.editCoupon({
      ...payLoad,
    });

    dispatch({
      type: promoType["LOADING_PROMO"],
      payLoad: false,
    });
    dispatch({
      type: promoType["EDIT_PROMO"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: promoType["LOADING_PROMO"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
/**
 * Edit Coupon
 * @param {object} payLoad {search:"notstore"}
 * @returns {void}
 * */
export const searchPromoCodes = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: promoType["LOADING_SEARCH"],
      payLoad: true,
    });
    const response = await promoCodeService.searchPromoCodes({
      ...payLoad,
    });

    dispatch({
      type: promoType["LOADING_SEARCH"],
      payLoad: false,
    });
    dispatch({
      type: promoType["SEARCH_PROMO"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: promoType["LOADING_SEARCH"],
      payLoad: false,
    });
  }
};
/**
 * Edit Coupon
 * @param {object} payLoad {company_id :"7b3e58e0-b68c-498d-b8d5-82198ef6d481"}
 * @returns {void}
 * */
export const filterPromoCodes = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({
      type: promoType["FILTERING"],
      payLoad: true,
    });
    const response = await promoCodeService.filterPromoCodes({
      ...payLoad,
      page,
    });
    dispatch({
      type: promoType["FILTERING"],
      payLoad: false,
    });
    dispatch({
      type: promoType["FILTER_PROMO"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: promoType["FILTERING"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
export const filterCouponsNoPage = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: promoType["FILTERING"],
      payLoad: true,
    });
    const response = await promoCodeService.filterCouponNoPage({
      ...payLoad,
    });
    dispatch({
      type: promoType["FILTERING"],
      payLoad: false,
    });
    dispatch({
      type: promoType["FILTER_PROMO_NOPAGE"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: promoType["FILTERING"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
/**
 * Show PromoCode
 * @param {object} payLoad {properties:1}
 * */
export const showPromoCode = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: promoType["LOADING_PROMO"],
      payLoad: true,
    });
    const response = await promoCodeService.showAPromoCode(payLoad);

    dispatch({
      type: promoType["LOADING_PROMO"],
      payLoad: false,
    });
    dispatch({
      type: promoType["SHOW_PROMO"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: promoType["LOADING_PROMO"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};
/**
 * Show my Coupon
 * @param {object} payLoad {properties:1}
 * */
export const showMyCoupon = () => async (dispatch) => {
  try {
    const response = await promoCodeService.showMyCoupon();
    dispatch({
      type: promoType["LOADING_PROMO"],
      payLoad: false,
    });
    dispatch({
      type: promoType["SHOW_MY_PROMO"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: promoType["LOADING_PROMO"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
