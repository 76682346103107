import React, { useState, Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import BreadCrumbs from "./breadcrumb";
import AdminBreadCrumbs from "./adminBreadCrumbs";
// import Logo from "../../../assets/images/icons/notstore-logo.svg";
import ORDERS from "../../../assets/images/icons/orders.svg";
import PROFILE from "../../../assets/images/icons/profile.svg";
import { PIMAGE } from "../../../assets/images/pictures/images";
import { ReactComponent as MENU } from "../../../assets/images/icons/menu.svg";
import { ReactComponent as LOGOUT } from "../../../assets/images/icons/logout.svg";
import { ReactComponent as ACCOUNTS } from "../../../assets/images/icons/accounts.svg";
import { ReactComponent as SWITCHICON } from "../../../assets/images/icons/account-icon.svg";
import { ReactComponent as SETTINGS } from "../../../assets/images/icons/settings.svg";
import { logoutUserLocallyAction } from "../../../redux/auth/unAuthActions";
import { ChooseAccountModal } from "../../modals/auth/chooseAccountModal";
import {
  getTeamFromCookies,
  getOutletFromCookies,
  getUserFromCookies,
} from "../../../utils/Auth";

const userNavigation = [
  {
    name: "Profile",
    href: "/profile/info",
    image: <img src={PROFILE} alt="icon" />,
  },
  {
    name: "Orders",
    href: "/profile/orders",
    image: <img src={ORDERS} alt="icon" />,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header({ setSidebarOpen }) {
  const dispatch = useDispatch();
  const params = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [admin, setAdmin] = useState(false);

  const team = getTeamFromCookies();
  const user = getUserFromCookies();

  useEffect(() => {
    if (team === "administrator" || team?.includes("management")) {
      setAdmin(true);
    } else {
      setAdmin(false);
    }
  }, [team]);

  const outlet = getOutletFromCookies();
  const outlet_name = outlet?.restaurant_name ?? outlet?.store_name;

  function getInitials(word) {
    const words = word.split(" ");
    return words.map((word) => {
      return word[0].toUpperCase();
    });
  }

  const onImageError = (e) => {
    e.target.src = PIMAGE;
  };

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  function logoutUser() {
    dispatch(logoutUserLocallyAction());
  }

  return (
    <div className="sticky top-0 z-10 flex items-center h-16 flex-shrink-0 bg-[#F6F8FF]">
      <button
        type="button"
        className="px-3 text-[#CE2600] focus:outline-none focus:ring-2 md:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <MENU className="h-6 w-6" aria-hidden="true" />
      </button>

      <a href="/" className="sm:hidden">
        {/* <img className="block h-8 w-auto" src={Logo} alt="NotStore" /> */}
        <span className="text-primary text-2xl font-bold">NOT STORE</span>
      </a>
      <div className="flex flex-1 justify-end sm:justify-between px-6">
        <div className="hidden sm:block">
          {admin ? <AdminBreadCrumbs /> : <BreadCrumbs />}
        </div>

        <div className="ml-4 flex gap-3 sm:gap-6 items-center md:ml-6  sm:flex">
          <ChooseAccountModal show={isOpen} onClose={closeModal} />
          {admin && (
            <Link to={"/admin/settings"} className="hidden sm:block">
              <span className="flex gap-1 text-[#4D4D4D]">
                <SETTINGS className="h-6 w-6" />
                <span
                  className={
                    params["*"].includes("settings") ? `text-primary` : ""
                  }
                >
                  Settings
                </span>
              </span>
            </Link>
          )}
          {!admin && (
            <Link
              to={`/admin/${outlet_name}/settings`}
              className="hidden sm:block"
            >
              <span className="flex gap-1 text-[#4D4D4D] hidden sm:flex">
                <SETTINGS className="h-6 w-6" />
                <span
                  className={
                    params["*"].includes("settings") ? `text-primary` : ""
                  }
                >
                  Settings
                </span>
              </span>
            </Link>
          )}
          <button
            type="button"
            className="flex gap-1 p-1 text-[#4D4D4D] hover:text-gray-500 outline-none cursor-pointer"
            onClick={openModal}
          >
            <span className="hidden gap-1 sm:flex">
              <ACCOUNTS className="h-6 w-6" />
              Accounts
            </span>
            <span className="sm:hidden">
              <SWITCHICON className="h-6 w-6" />
            </span>
          </button>

          <span
            className="flex gap-1 text-[#4D4D4D] cursor-pointer hidden sm:flex"
            onClick={logoutUser}
          >
            <LOGOUT className="h-6 w-6" /> Log Out
          </span>

          <Menu as="div" className="flex-shrink-0 relative ml-1">
            {/* Profile dropdown */}
            <Menu.Button className="bg-white rounded-full flex focus:outline-none items-center">
              {user?.picture ? (
                <img
                  src={user?.picture}
                  onError={onImageError}
                  className="w-8 h-8 rounded-full"
                  alt="user"
                />
              ) : (
                <div className="w-[34px] h-[34px] rounded-full text-lg bg-gray-200 flex items-center justify-center mr-1">
                  {getInitials(user?.name)}
                </div>
              )}
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <a
                        href={item.href}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block py-2 px-4 text-sm text-gray-700"
                        )}
                      >
                        {item.name}
                      </a>
                    )}
                  </Menu.Item>
                ))}
                <button
                  className="block py-2 px-4 text-sm text-gray-700"
                  onClick={logoutUser}
                >
                  Sign out
                </button>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
}
