import React, { useMemo, useEffect, useState, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Beat } from "../../../../../plugins/spinners.plugin";
import Button from "../../../../../components/buttons/buttons";
import Card from "../../../../../components/cards/admin/cards";
import Table from "../../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../../components/skeleton/table";
import { Pagination } from "../../../../../components/pagination/pagination";
import { AllFiltersModal } from "../../../../../components/modals/allFiltersModal";
import FilterUsersDropDown from "../../../../../components/dropDown/filter/filterUsers";
import {
  filterUsersAction,
  searchUsersAction,
  getAllUsersNoPagination,
} from "../../../../../redux/users/userActions";
import userType from "../../../../../redux/users/userTypes";
import { writeFileWithXLSX } from "../../../../../components/exports/xlsx";

export default function All() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page } = useParams();
  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);

  const [isFilterOpen, setFilterState] = useState(false);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });
  const [payLoad] = useState({
    start_date: lastMonth,
    end_date: new Date(),
  });

  const { allUsers, downloading, filtering, searching } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    dispatch(filterUsersAction(payLoad, page));
    // eslint-disable-next-line
  }, [page]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((user, index) => {
        let show = {
          id: index + 1,
          name: user.name,
          email: user?.email,
          created_at: moment(user?.created_at).format("MMM DD, yyyy"),
          phone: user?.phone ? <span>+{user?.phone}</span> : "Not available",
          blocked: user?.blocked ? <span>True</span> : <span>False</span>,
          user: user,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  function createTableDataDownload(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((user, index) => {
        let show = {
          id: index + 1,
          name: user.name,
          email: user?.email,
          created_at: moment(user?.created_at).format("MMM DD, yyyy"),
          phone: user?.phone ? user?.phone : "Not Available",
          blocked: user?.blocked,
        };
        outputArray.push(show);
        return true;
      });
      return outputArray;
    }
  }

  useLayoutEffect(() => {
    const dataSet = createTableData(allUsers?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUsers]);

  function goToSinglePage(params) {
    let id = params?.show?.user?.id;
    navigate(`/admin/report/users/${id}`, {
      state: { user: params?.show?.user },
    });
  }

  const handleDownload = async () => {
    const response = await dispatch(
      getAllUsersNoPagination({
        ...payLoad,
        pagination: 0,
      })
    );
    if (response) {
      const newData = createTableDataDownload(response?.data);
      writeFileWithXLSX(newData, {
        filename: "UsersReport_All_Users.xlsx",
        download: true,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allUsers?.data);
      setData(dataSet);
      dispatch({
        type: userType["SEARCH_USERS"],
        payLoad: { data: [] },
      });
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchUsersAction(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.id",
          },
          {
            Header: "Name",
            accessor: "show.name",
          },
          {
            Header: "Email",
            accessor: "show.email",
          },
          {
            Header: "Created On",
            accessor: "show.created_at",
          },
          {
            Header: "Phone Number",
            accessor: "show.phone",
          },
          {
            Header: "Blocked",
            accessor: "show.blocked",
          },
        ],
      },
    ],
    []
  );

  return (
    <div>
      <Card.TableFilter
        results={allUsers?.to}
        totalResults={allUsers?.total}
        title={"Users"}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterUsersDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
        searchPlaceholder="Name, Email, Phone number"
      >
        <Button.Secondary
          title={downloading ? <Beat color={"#CE2600"} /> : "Download CSV"}
          type="button"
          onClick={handleDownload}
        />
      </Card.TableFilter>

      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white rounded-md py-4">
        {filtering || searching ? (
          <TableSkeleton />
        ) : (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        )}

        <Pagination data={allUsers} route={"admin/report/users/all"} />
      </div>
    </div>
  );
}
