import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//components
import Container from "../../../components/container/container";
import NavBarUser from "../../../components/navigation/NavBarUser";
import Location from "../../../components/includes/location";
import Input from "../../../components/inputs/inputs";
import OrderSummary from "./OrderSummary";
import { Beat } from "../../../plugins/spinners.plugin";
//icons
import ArrowDown from "../../../assets/images/icons/arrow-down.svg";
import Warning from "../../../assets/images/icons/warning-circle.svg";
import { cleanInput } from "../../../helpers/cleanInput";
import {
  showMyCart,
  showMyCartNoProperties,
  updateCoupon,
  storePublicCartProducts,
} from "../../../redux/cart/cartAction";
import { getMyAddressesAction } from "../../../redux/address/addressActions";
import { isAuthenticatedUser, getUserFromCookies } from "../../../utils/Auth";
import { filterPublicServiceAreas } from "../../../redux/publicServiceAreas/publicServiceAreasActions";

export default function CartGuestCheckout() {
  const dispatch = useDispatch();
  const isAuth = isAuthenticatedUser();
  const user = getUserFromCookies();
  const localCart = JSON.parse(localStorage.getItem("notstore-cart"));

  const [myCart, setMyCart] = useState([]);
  const [openDelivery, setOpenDelivery] = useState(false);
  const [payLoad] = useState({ end_date: new Date(), pagination: 0 });
  const [submitted, setSubmitted] = useState(false);
  const [isContinueClicked, setIsContinueClicked] = useState(false);
  const [checkout_codes, setCheckoutCodes] = useState({
    checkout_code_slug: "",
  });
  const { checkout_code_slug } = checkout_codes;

  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "Enugu",
    state: "Enugu",
    country: "Nigeria",
    products: [],
    packages: [],
    service_area_id: "1",
    checkout_codes: [],
  });

  // const { myCarts } = useSelector((state) => state.cart);
  const { cartProducts, updatingCoupon } = useSelector((state) => state.cart);
  const { filter, filtering } = useSelector((state) => state.publicServiceArea);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(filterPublicServiceAreas(payLoad));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAuth) {
      dispatch(getMyAddressesAction());
      dispatch(showMyCart());
      dispatch(showMyCartNoProperties());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    cartProducts && setMyCart(cartProducts?.data);
    if (cartProducts) {
      let value =
        cartProducts?.data?.coupons?.[0]?.coupon_slug ||
        cartProducts?.data?.promotion_codes?.[0]?.promotion_code_slug;
      setData({
        ...data,
        products: cartProducts?.data?.products,
        packages: cartProducts?.data?.packages,
        checkout_codes:
          cartProducts?.data?.coupons?.length > 0 ||
          cartProducts?.data?.promotion_codes?.length > 0
            ? [{ checkout_code_slug: value }]
            : [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartProducts]);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    if (name === "checkout_code_slug") {
      setCheckoutCodes({ ...checkout_codes, [name]: value });
      setData({ ...data, checkout_codes: [{ checkout_code_slug: value }] });
    } else {
      setData({ ...data, [name]: value });
    }
    if (name === "address" || name === "service_area_id") {
      setIsContinueClicked(false);
    }
  };

  function getCartTotal() {
    //check if the user is logged in first if the user is login,
    //get the total from the sum of the online cart packages and products
    //else get the total from the local storage package and products
    if (isAuth && (myCart?.products || myCart?.packages)) {
      let product_no = myCart?.products ? Number(myCart?.products?.length) : 0;
      let package_no = myCart?.packages ? Number(myCart?.packages?.length) : 0;
      return product_no + package_no;
    } else {
      if (localCart?.products || localCart?.packages) {
        let product_local = localCart?.products
          ? Number(localCart?.products?.length)
          : 0;
        let package_local = localCart?.packages
          ? Number(localCart?.packages?.length)
          : 0;
        return product_local + package_local;
      } else {
        return 0;
      }
    }
  }

  const cartTotal = getCartTotal();

  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const [locationLoader, setLocationLoader] = useState(false);
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  const applyCoupon = async () => {
    const localCart = JSON.parse(localStorage.getItem("notstore-cart"));
    setSubmitted(true);
    if (isAuth) {
      if (checkout_code_slug) {
        setSubmitted(false);
        try {
          const response = await dispatch(
            updateCoupon({
              user_id: user?.id,
              checkout_codes: [checkout_codes],
            })
          );
          response && dispatch(showMyCart());
        } catch (error) {
          console.log("error", error);
        }
      }
    } else {
      if (localCart) {
        let updateCart = localCart;
        //if there are items in the local cart
        if (checkout_code_slug) {
          updateCart.checkout_codes = [checkout_codes];
        }
        try {
          cleanInput(updateCart);
          const response = await dispatch(storePublicCartProducts(updateCart));
          if (response) {
            localStorage.setItem(
              "notstore-cart-items",
              JSON.stringify(response?.data)
            );
            localStorage.setItem("notstore-cart", JSON.stringify(updateCart));
          }
        } catch (error) {
          console.log("error", error);
        }
      } else {
        let updateCart = {
          checkout_codes: [checkout_codes],
        };
        try {
          cleanInput(updateCart);
          const response = await dispatch(storePublicCartProducts(updateCart));
          if (response) {
            localStorage.setItem(
              "notstore-cart-items",
              JSON.stringify(response?.data)
            );
            localStorage.setItem("notstore-cart", JSON.stringify(updateCart));
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    }
  };

  const removeCoupon = async (myCarts) => {
    const localCart = JSON.parse(localStorage.getItem("notstore-cart"));
    if (isAuth) {
      if (
        myCarts?.coupons?.length > 0 ||
        myCarts?.promotion_codes?.length > 0
      ) {
        try {
          const response = await dispatch(
            updateCoupon({
              user_id: user?.id,
              checkout_codes: null,
              coupons: null,
              promotion_codes: null,
            })
          );
          if (response?.status_code === 200) {
            dispatch(showMyCart());
            setCheckoutCodes({ checkout_code_slug: "" });
            setData({
              ...data,
              checkout_codes: [],
            });
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    } else {
      if (localCart) {
        let updateCart = localCart;
        updateCart.checkout_codes = null;
        updateCart.coupons = null;
        updateCart.promotion_codes = null;
        //if there are items in the local cart
        try {
          cleanInput(updateCart);
          const response = await dispatch(storePublicCartProducts(updateCart));
          if (response) {
            localStorage.setItem(
              "notstore-cart-items",
              JSON.stringify(response?.data)
            );
            localStorage.setItem("notstore-cart", JSON.stringify(updateCart));
            setCheckoutCodes({ checkout_code_slug: "" });
            setData({
              ...data,
              checkout_codes: [],
            });
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    }
  };

  return (
    <div data-test="cartComponent">
      <NavBarUser />
      <div className="shadow-md mb-6 sm:mb-16">
        <Container>
          <div className="py-4 sm:pt-12 sm:pb-6">
            <h2
              className="text-[#333333] text-3xl sm:text-5xl font-bold mb-0 sm:mb-4"
              style={{ lineHeight: "64px" }}
            >
              My Cart
            </h2>
            <p className="text-lg text-gray-400 -mt-1 sm:mt-0">
              {cartTotal} Item
              {cartTotal > 1 ? "s" : ""} in the cart
            </p>
          </div>
        </Container>
      </div>

      <Container>
        <main className="pb-8">
          <div className="max-w-3xl mx-auto lg:max-w-7xl">
            {/* Main 3 column grid */}
            <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-16">
              {/* Left column */}
              <div className="grid grid-cols-1 gap-4 lg:col-span-2 max-w-[610px]">
                <section aria-labelledby="section-1-title">
                  <h2 className="sr-only" id="section-1-title">
                    Section title - Contact Details
                  </h2>
                  {/* Your content */}

                  <div>
                    <div className="rounded-lg bg-white overflow-hidden border px-4 py-5 mb-6 shadow-lg">
                      <div className="mb-10">
                        <h2 className="text-xl text-left font-bold pb-2 border-b mb-4">
                          Contact Details
                        </h2>
                        <p className="text-center">
                          Please complete your contact information and click
                          "Pay Now" to complete your order
                        </p>
                      </div>
                      {/* Contact details form */}
                      <div>
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium"
                        >
                          Name
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
                            placeholder="Enter your name"
                            defaultValue={data?.name}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>
                      <div className="mt-4 sm:mt-0">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium"
                        >
                          Email
                        </label>
                        <div className="mt-2">
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
                            placeholder="Enter your email"
                            value={data?.email}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="phone"
                          className="block text-sm font-medium"
                        >
                          Phone
                        </label>
                        <div className="mt-2">
                          <input
                            type="number"
                            name="phone"
                            id="phone"
                            className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
                            placeholder="Enter phone number e.g: 08123456789"
                            defaultValue={data?.phone}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>

                      {/* Delivery details form */}
                      <div className="mt-8 mb-8">
                        <h2 className="font-bold text-xl mb-2">
                          Delivery Details
                        </h2>
                        <div className="flex justify-between items-center gap-4">
                          <div className="flex items-center gap-2">
                            <img
                              src={Warning}
                              className="w-[18px] h-[18px]"
                              alt="warning"
                            />
                            <p className="text-sm text-[#9CA3AF]">
                              Kindly fill in address details if this order must
                              be delivered to your current location.
                            </p>
                          </div>

                          <button
                            className="outline-none"
                            onClick={() => setOpenDelivery(!openDelivery)}
                          >
                            <img
                              src={ArrowDown}
                              className="w-[18px] h-[18px]"
                              alt="warning"
                            />
                          </button>
                        </div>
                      </div>

                      {openDelivery && (
                        <div>
                          <div>
                            <label
                              htmlFor="phone"
                              className="block text-sm font-medium"
                            >
                              Street Address
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="address"
                                id="address"
                                className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
                                placeholder="Enter street address"
                                defaultValue={data?.address}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>

                          <Location
                            country_id={locationId.country_id}
                            state_id={locationId.state_id}
                            city_id={locationId.city_id}
                            loadingLocation={(value) => {
                              setLocationLoader(value);
                            }}
                            countryList={(value) => {
                              setLocationList({
                                ...locationList,
                                countryList: value,
                              });
                            }}
                            stateList={(value) => {
                              setLocationList({
                                ...locationList,
                                stateList: value,
                              });
                            }}
                            cityList={(value) => {
                              setLocationList({
                                ...locationList,
                                cityList: value,
                              });
                            }}
                          />

                          <div className="mt-6">
                            <Input.Select
                              title={"Select Country"}
                              name="country"
                              id="country"
                              className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                              htmlFor={"country"}
                              onChange={(e) => {
                                setLocationId({
                                  ...locationId,
                                  country_id: fromJson(e.target.value).id,
                                });
                                handleRegion(e);
                              }}
                            >
                              <option>Select Country</option>
                              {locationLoader ? (
                                <option>Loading...</option>
                              ) : (
                                locationList?.countryList &&
                                locationList?.countryList?.length > 0 &&
                                locationList?.countryList?.map((item, key) => {
                                  return (
                                    <option
                                      key={key}
                                      value={toJson({
                                        name: item.name,
                                        id: item.id,
                                      })}
                                    >
                                      {item.name}
                                    </option>
                                  );
                                })
                              )}
                            </Input.Select>
                          </div>
                          <div className="mt-6">
                            <Input.Select
                              title={"Select State"}
                              name="state"
                              id="state"
                              className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                              htmlFor={"state"}
                              onChange={(e) => {
                                setLocationId({
                                  ...locationId,
                                  state_id: fromJson(e.target.value).id,
                                });
                                handleRegion(e);
                              }}
                            >
                              <option>Select State</option>
                              {locationLoader ? (
                                <option>Loading...</option>
                              ) : (
                                locationList?.stateList &&
                                locationList?.stateList?.length > 0 &&
                                locationList?.stateList?.map((item, key) => {
                                  return (
                                    <option
                                      key={key}
                                      value={toJson({
                                        name: item.name,
                                        id: item.id,
                                      })}
                                    >
                                      {item.name}
                                    </option>
                                  );
                                })
                              )}
                            </Input.Select>
                          </div>
                          <div className="mt-6">
                            <Input.Select
                              title={"Select City"}
                              name="city"
                              id="city"
                              className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                              htmlFor={"city"}
                              onChange={(e) => {
                                setLocationId({
                                  ...locationId,
                                  city_id: fromJson(e.target.value).id,
                                });
                                handleRegion(e);
                              }}
                            >
                              <option>Select City</option>
                              {locationLoader ? (
                                <option>Loading...</option>
                              ) : (
                                locationList?.cityList &&
                                locationList?.cityList?.length > 0 &&
                                locationList?.cityList?.map((item, key) => {
                                  return (
                                    <option
                                      key={key}
                                      value={toJson({
                                        name: item.name,
                                        id: item.id,
                                      })}
                                    >
                                      {item.name}
                                    </option>
                                  );
                                })
                              )}
                            </Input.Select>
                          </div>
                          <div className="mt-6">
                            <Input.Select
                              title={"Service Area"}
                              name="service_area_id"
                              id="service_area_id"
                              className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                              htmlFor={"service_area_id"}
                              onChange={handleChange}
                            >
                              <option>Select service area</option>
                              {filtering
                                ? "Loading..."
                                : filter?.length > 0 &&
                                  filter?.map((item, i) => {
                                    return (
                                      <option key={i} value={item?.id}>
                                        {item.area}
                                      </option>
                                    );
                                  })}
                            </Input.Select>
                          </div>
                        </div>
                      )}

                      {/* Add a coupon or promo code */}
                      <div className="mt-16 sm:max-w-3xl">
                        <h3 className="text-xl font-medium mb-2">
                          Add a coupon (optional)
                        </h3>
                        <p className="mb-4">
                          If you have a coupon code, please apply it below.
                        </p>

                        {myCart?.coupons?.length > 0 ||
                        myCart?.promotion_codes?.length > 0 ? (
                          <div className="flex gap-3">
                            <input
                              type={"text"}
                              placeholder="Enter coupon code"
                              name={"checkout_codes"}
                              value={
                                myCart?.coupons?.[0]?.coupon_slug ||
                                myCart?.promotion_codes?.[0]
                                  ?.promotion_code_slug
                              }
                              disabled
                              className="mt-2 flex-1 h-12 pl-3 pr-10 w-full rounded-lg text-lg text-[#676565] bg-[#C4C4C4] focus:outline-none sm:text-sm px-4 py-2 mb-4 border border-[#DADADA] "
                            />
                            <button
                              type="button"
                              disabled={updatingCoupon}
                              className="justify-center h-12 rounded-md border border-primary shadow-sm px-4 py-3 mt-2 mb-4 bg-white text-primary font-medium sm:text-sm"
                              onClick={() => {
                                removeCoupon();
                              }}
                            >
                              {updatingCoupon ? (
                                <Beat color={"#CE2600"} />
                              ) : (
                                "Remove Coupon"
                              )}
                            </button>
                          </div>
                        ) : (
                          <div className="flex gap-3">
                            <input
                              type={"text"}
                              placeholder="Enter coupon code"
                              name={"checkout_code_slug"}
                              onChange={(e) => handleChange(e)}
                              value={checkout_code_slug}
                              className="mt-2 flex-1 h-12 pl-3 pr-10 w-full rounded-lg text-base bg-[#FFFFFF] focus:ring focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm px-4 py-2 mb-4 border border-gray-300 "
                            />
                            <button
                              type="button"
                              disabled={updatingCoupon}
                              className="justify-center h-12 rounded-md border border-primary shadow-sm px-4 py-3 mt-2 mb-4 bg-primary text-white font-medium sm:text-sm"
                              onClick={() => applyCoupon()}
                            >
                              {updatingCoupon ? <Beat /> : "Apply Coupon"}
                            </button>
                          </div>
                        )}
                      </div>
                      {submitted && checkout_codes?.length < 1 && (
                        <p className="text-primary text-sm">
                          You have not entered any coupon code!
                        </p>
                      )}
                    </div>
                  </div>
                </section>
              </div>

              {/* Right column */}
              <OrderSummary
                myCart={myCart}
                setMyCart={setMyCart}
                orderData={data}
                isContinueClicked={isContinueClicked}
                setIsContinueClicked={setIsContinueClicked}
              />
            </div>
          </div>
        </main>
      </Container>
    </div>
  );
}
