import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cleanInput } from "../../../../helpers/cleanInput";
import Location from "../../../../components/includes/location";
import { createShipper } from "../../../../redux/shippers/shippersActions";
import PersonalDetails from "./personalDetails";
import ShippersRate1 from "./shippersRate1";
import ShippersRate2 from "./shippersRate2";

export default function CreateShipper() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [active_tab, setActiveTab] = useState("personal details");
  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });

  //const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  let [phone, setPhone] = useState("");
  const [data, setData] = useState({
    name: "",
    currency: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    insurance_percentage: "",
    tax_percentage: "",
    discount_percentage: "",
    vat_percentage: "",
    base_fee: "",
    base_fee_per_meter: "",
    base_fee_per_kilogram: "",
    base_fees_by_delivery_mode: [],
    weight_limit_by_kilogram: "",
  });
  const {
    address,
    name,
    currency,
    city,
    state,
    country,
    zip,
    insurance_percentage,
    base_fee_per_meter,
    tax_percentage,
    discount_percentage,
    vat_percentage,
    base_fee,
    base_fees_by_delivery_mode,
    base_fee_per_kilogram,
    weight_limit_by_kilogram,
  } = data;

  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setData({ ...data, [name]: value });
  };

  const handleNext = () => {
    setSubmitted(true);
    if (
      name &&
      phone &&
      address &&
      city &&
      state &&
      address &&
      country &&
      zip &&
      currency
    ) {
      setActiveTab("shippers-Rate-1");
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleNext2 = () => {
    setSubmitted(true);
    if (
      base_fee_per_meter &&
      insurance_percentage &&
      tax_percentage &&
      discount_percentage &&
      base_fee
    ) {
      setActiveTab("shippers-Rate-2");
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  // const removebyKilogram = (value) => {
  //   setData({ ...data, base_fee_per_kilogram: value });
  // };
  const removebyDelivery = (value) => {
    setData({ ...data, base_fees_by_delivery_mode: value });
  };
  const handleBack = () => {
    setActiveTab("personal details");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleBack2 = () => {
    setActiveTab("shippers-Rate-1");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  phone = phone?.slice(1);

  const handleSubmit = async (value) => {
    cleanInput(data);
    setSubmitted(true);
    // let delivery = data?.base_fees_by_delivery_mode ?? [];

    // value.base_fees_by_delivery_mode &&
    //   delivery.push(value.base_fees_by_delivery_mode);

    if (
      name &&
      currency &&
      insurance_percentage &&
      vat_percentage &&
      phone &&
      base_fee_per_meter &&
      address &&
      city &&
      state &&
      country &&
      base_fees_by_delivery_mode &&
      base_fee_per_meter &&
      base_fee_per_kilogram &&
      zip &&
      weight_limit_by_kilogram
    ) {
      const response = await dispatch(
        createShipper({
          ...data,
          phone,
          // base_fees_per_kilogram: kilogram,
          // base_fees_by_location: location,
          // base_fees_by_delivery_mode: delivery,
        })
      );
      if (response) {
        navigate("/admin/shippers/unapproved/1");
      }
    }
  };
  // const handleDefault = async () => {
  //   cleanInput(data);
  //   setSubmitted(true);
  //   if (
  //     name &&
  //     currency &&
  //     insurance_percentage &&
  //     phone &&
  //     base_fee_per_meter &&
  //     address &&
  //     city &&
  //     state &&
  //     country &&
  //     base_fee_per_meter &&
  //     base_fees_by_delivery_mode &&
  //     base_fee_per_kilogram &&
  //     zip &&
  //     weight_limit_by_kilogram
  //   ) {
  //     const response = await dispatch(
  //       createShipper({
  //         ...data,
  //         phone,
  //         shipper_selected: 1,
  //       })
  //     );
  //     if (response) {
  //       navigate("/admin/shippers/unapproved/1");
  //     }
  //   }
  // };
  return (
    <>
      <div className="pt-4 sm:pt-2 sm:flex items-center mx-auto">
        <div className="max-w-4xl mx-auto">
          {active_tab === "personal details" && (
            <PersonalDetails
              handleChange={handleChange}
              data={data}
              phone={phone}
              setPhone={setPhone}
              handleSubmit={handleSubmit}
              submitted={submitted}
              Location={Location}
              locationId={locationId}
              locationList={locationList}
              setLocationList={setLocationList}
              setLocationId={setLocationId}
              handleRegion={handleRegion}
              handleNext={handleNext}
            />
          )}
          {active_tab === "shippers-Rate-1" && (
            <ShippersRate1
              handleChange={handleChange}
              handleBack={handleBack}
              data={data}
              handleSubmit={handleSubmit}
              submitted={submitted}
              handleNext2={handleNext2}
            />
          )}
          {active_tab === "shippers-Rate-2" && (
            <ShippersRate2
              handleChange={handleChange}
              handleBack2={handleBack2}
              // removebyKilogram={removebyKilogram}
              removebyDelivery={removebyDelivery}
              data={data}
              setData={setData}
              handleSubmit={handleSubmit}
              submitted={submitted}
              // handleDefault={handleDefault}
            />
          )}
        </div>
      </div>
    </>
  );
}
