import React, { Fragment, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import CLOSE from "../../../assets/images/icons/close.svg";
// import LOGO from "../../../assets/images/icons/notstore-o.svg";
import FLUTTER from "../../../assets/images/icons/flutter.svg";
import PAYSTACK from "../../../assets/images/icons/paystack.svg";
import TRANSFER from "../../../assets/images/icons/bank-transfer.svg";
import PaymentSkeleton from "../../skeleton/payment";
import PaystackPlugin from "../../../pages/common/payments/PaystackOnClick";
import FlutterwavePlugin from "../../../pages/common/payments/FlutterwaveOnClick";
import PaystackTransferPlugin from "../../../pages/common/payments/PaystackBankTransfer";
import { getUserFromCookies } from "../../../utils/Auth";
import { showApplication } from "../../../redux/applicationSettings/applicationAction";
import { getAllPaymentProviders } from "../../../redux/payment/paymentActions";

export default React.forwardRef(function PaymentModal(
  {
    visibility = false,
    primaryButtonTitle,
    primaryButton,
    primaryButtonStyle,
    primaryButtonClassName,
    modalTitle,
    modalTitleSection,
    modelContent,
    modelContentSection,
    disableClose = false,
    disableOpen = false,
    onOpen = () => {},
    onClose = () => {},
    payment_type,
    receipt_email,
  },
  ref
) {
  // Ref handler
  React.useImperativeHandle(ref, () => ({
    open: () => openModal(),
    close: () => closeModal(),
    getTotalPrice: (data) => getTotalPrice(data),
    getReference: (data) => getReferenceId(data),
  }));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = getUserFromCookies();
  const paystackRef = useRef(null);
  const flutterRef = useRef(null);
  const paystackTransferRef = useRef(null);
  let [isOpen, setIsOpen] = React.useState(visibility);
  let [totalPrice, setTotalPrice] = React.useState("");
  let [reference_id, setReferenceId] = React.useState("");
  const { settings, isLoading } = useSelector((state) => state.application);
  const { allProviders } = useSelector((state) => state.payment);

  useEffect(() => {
    dispatch(showApplication());
    dispatch(getAllPaymentProviders());
    // eslint-disable-next-line
  }, []);

  function closeModal() {
    !disableClose && setIsOpen(false);
    !disableClose && onClose();
  }

  function openModal() {
    !disableOpen && setIsOpen(true);
    !disableOpen && onOpen();
  }

  function getTotalPrice(data) {
    setTotalPrice(data);
  }

  function getReferenceId(data) {
    setReferenceId(data);
  }

  function goToBank() {
    navigate(`/bank-transfer`, {
      state: { totalPrice: totalPrice },
    });
  }

  function goToOrders() {
    setIsOpen(false);
  }
  return (
    <React.Fragment>
      <div className="">
        {primaryButton ? (
          <span className="cursor-pointer" onClick={openModal}>
            {primaryButton}
          </span>
        ) : (
          <button
            type="button"
            style={primaryButtonStyle}
            onClick={openModal}
            disabled={primaryButtonStyle?.disabled}
            className={
              "glade-normal-text-three glade-button-blue whitespace-nowrap w-11/12 py-2 px-4 flex justify-center items-center text-white text-center rounded-sm " +
              primaryButtonClassName
            }
          >
            {primaryButtonTitle ?? "Title"}
          </button>
        )}
      </div>

      <Transition appear show={visibility || isOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-[629px]  bg-white rounded-sm px-4 pt-5 pb-4  overflow-hidden shadow-xl transform transition-all sm:my-10 sm:max-w-lg sm:w-full  sm:p-8">
                  <div className="sm:block absolute top-2 right-3 pt-4 pr-4">
                    <button type="button" className="" onClick={closeModal}>
                      <span className="sr-only">Close</span>
                      <img
                        src={CLOSE}
                        alt="close"
                        className=" w-3 h-3 sm:w-4 sm:h-4"
                      />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="text-center mx-auto ">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-meduim text-black"
                      >
                        How would you like to pay?
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="mt-3 sm:mt-2">
                    <div className=" border-b-2 "></div>
                    <div className="mt-5">
                      {isLoading ? (
                        <PaymentSkeleton />
                      ) : (
                        <ul>
                          {settings?.notstore_payment_status === "available" && (
                            <li
                              className="flex mb-4 bg-white h-20 shadow py-4 text-left rounded-sm cursor-pointer hover:bg-gray-100 group hover:shadow"
                              onClick={goToBank}
                            >
                              {/* <img
                                src={LOGO}
                                alt="NotStore o logo"
                                className="bg-gray-100 p-2 ml-3 w-12 h-12 rounded-lg "
                              /> */}
                              <span className="text-primary text-xl font-bold">
                                NOT STORE
                              </span>
                              <div className="ml-3 overflow-hidden ">
                                <p className=" text-lg font-meduim text-black ">
                                  NotStore Pay
                                </p>
                                <p className=" text-base text-[#676565] font-normal">
                                  Transfer into NotStore account
                                </p>
                              </div>
                            </li>
                          )}
                          {settings?.pay_stack_payment_status === "available" &&
                            allProviders?.pay_stack_key && (
                              <li
                                className="flex mb-4 bg-white h-20 shadow py-4 text-left rounded-sm cursor-pointer hover:bg-gray-100 group hover:shadow"
                                onClick={() =>
                                  paystackTransferRef.current.getAmount_Reference(
                                    totalPrice,
                                    reference_id
                                  )
                                }
                              >
                                <img
                                  src={TRANSFER}
                                  alt="logo"
                                  className="bg-gray-100 p-2 ml-3 w-12 h-12 rounded-lg "
                                />
                                <div className="ml-3 overflow-hidden ">
                                  <p className=" text-lg font-meduim text-black ">
                                    Bank Transfer
                                  </p>
                                  <p className=" text-base text-[#676565] font-normal">
                                    Transfer into NotStore account
                                  </p>
                                </div>
                              </li>
                            )}
                          <PaystackTransferPlugin
                            amount={totalPrice}
                            email={user?.email}
                            reference={reference_id}
                            payment_type={payment_type}
                            onClose={goToOrders}
                            onSuccess={goToOrders}
                            ref={paystackTransferRef}
                          />
                          {settings?.pay_stack_payment_status === "available" &&
                            allProviders?.pay_stack_key && (
                              <li
                                onClick={() =>
                                  paystackRef.current.getAmount_Reference(
                                    totalPrice,
                                    reference_id
                                  )
                                }
                                className="flex w-full mb-4 bg-white h-20 shadow rounded-sm py-4 text-left cursor-pointer hover:bg-gray-100 group hover:shadow"
                              >
                                <img
                                  src={PAYSTACK}
                                  alt="payStack logo"
                                  className="bg-gray-100 p-4 ml-3 w-12 h-12 rounded-lg "
                                />
                                <div className="ml-3 overflow-hidden ">
                                  <p className="text-lg font-meduim text-black ">
                                    Paystack
                                  </p>
                                  <p className=" text-base text-[#676565] font-normal">
                                    Pay with Card, USSD, Bank Transfer or Visa
                                    QR
                                  </p>
                                </div>
                              </li>
                            )}

                          <PaystackPlugin
                            amount={totalPrice}
                            email={
                              user?.email
                                ? user?.email
                                : receipt_email
                                ? receipt_email
                                : "hello@notstore.com"
                            }
                            onClose={goToOrders}
                            onSuccess={goToOrders}
                            payment_type={payment_type}
                            reference={reference_id}
                            ref={paystackRef}
                          />

                          {settings?.flutter_wave_payment_status ===
                            "available" &&
                            allProviders?.flutter_wave_key && (
                              <li
                                onClick={() => {
                                  flutterRef.current.getAmount_Reference(
                                    totalPrice,
                                    reference_id
                                  );
                                }}
                                className="flex mb-4 bg-white h-20  shadow-lg py-4 text-left rounded-sm cursor-pointer hover:bg-gray-100 group hover:shadow"
                              >
                                <img
                                  src={FLUTTER}
                                  alt="NotStore o logo"
                                  className="bg-gray-100 p-2 ml-3 w-12 h-12 rounded-lg "
                                />
                                <div className="ml-3 overflow-hidden">
                                  <p className="text-xl font-meduim text-black">
                                    Flutterwave
                                  </p>
                                  <p className=" text-base text-[#676565] font-normal ">
                                    Pay with Card, USSD, Bank Transfer.
                                  </p>
                                </div>
                              </li>
                            )}

                          <FlutterwavePlugin
                            amount={totalPrice}
                            email={
                              user?.email
                                ? user?.email
                                : receipt_email
                                ? receipt_email
                                : "hello@notstore.com"
                            }
                            phone_number={user?.phone}
                            name={user?.name}
                            tx_ref={reference_id}
                            payment_type={payment_type}
                            onClose={goToOrders}
                            onSuccess={goToOrders}
                            ref={flutterRef}
                          />
                        </ul>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
});
