import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { XIcon } from "@heroicons/react/outline";
import axios from "axios";
import Button from "../../../../../components/buttons/buttons";
import Input from "../../../../../components/inputs/inputs";
import Location from "../../../../../components/includes/location";
import { Beat } from "../../../../../plugins/spinners.plugin";
import SelectableInput from "../../../../../components/inputs/SelectableInput";
import { cleanInput } from "../../../../../helpers/cleanInput";
import {
  getOutletFromCookies,
  getToken,
  getTeamFromCookies,
} from "../../../../../utils/Auth";
import { getSingleStore } from "../../../../../redux/stores/storeActions";
import { filterProductCategory } from "../../../../../redux/productCatergory/productCategoryAction";
import { getAllTags } from "../../../../../redux/productTags/productTagActions";
import successHandler from "../../../../../handlers/successHandler";
import errorHandler from "../../../../../handlers/errorHandler";

export default function EditStoreInformation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const outlet = getOutletFromCookies();
  const hiddenFileInput = useRef(null);
  const hiddenBannerInput = useRef(null);

  const { singleStore, updating } = useSelector((state) => state.store);
  const { allCategories } = useSelector((state) => state.productCatergory);
  const { allTags } = useSelector((state) => state.productTag);

  const [images, setImages] = useState(outlet?.store_pictures);
  const [loading, setLoading] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [image64, setImage64] = useState({});
  const [input, setInput] = useState({
    id: outlet?.id,
    business_id: outlet?.business_id,
    store_email: outlet?.store_email,
    store_name: outlet?.store_name,
    store_phone: outlet?.store_phone,
    store_address: outlet?.store_address,
    store_categories: outlet?.store_categories,
    store_tags: outlet?.store_tags,
    store_city: outlet?.store_city,
    store_country: outlet?.store_country,
    store_state: outlet?.store_state,
    base64_banners: "",
    store_operating_status: outlet?.store_operating_status,
  });
  const {
    store_email,
    store_name,
    store_phone,
    store_address,
    store_categories,
    store_tags,
    store_country,
    store_state,
    store_city,
    store_operating_status,
  } = input;
  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  useEffect(() => {
    dispatch(getSingleStore(outlet?.id));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getAllTags());
    dispatch(filterProductCategory({ type: "store" }, 1));
    // eslint-disable-next-line
  }, []);

  const storeCategories = allCategories?.data?.filter((item) => {
    return item?.type === "store";
  });
  const storeTags = allTags?.data?.filter((item) => {
    return item?.type === "store";
  });

  function handleRegion(e) {
    const { name, value } = e.target;
    setInput((input) => ({ ...input, [name]: fromJson(value).name }));
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleAddTags = (value) => {
    let former_tags = [];
    if (input?.store_tags) {
      former_tags = [...input?.store_tags];
    } else {
      former_tags = [];
    }
    let new_tags = [...former_tags, value.name];
    setInput({ ...input, store_tags: new_tags });
  };
  const handleAddCategories = (value) => {
    const former_categories = [...input?.store_categories];
    let new_categories = [...former_categories, value.name];
    setInput({ ...input, store_categories: new_categories });
  };

  const handleStorePhone = (e) => {
    let store_phone = e?.slice(1);
    setInput({ ...input, store_phone: store_phone });
  };
  function onImageChange(e) {
    setImages([...images, ...e.target.files]);
  }
  const handleFileClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };
  const removeFile = (index) => {
    let newArr = images;
    newArr.splice(index, 1);
    setImages([...newArr]);
  };

  const updateInput = useCallback(() => {
    setInput((prevInput) => ({
      ...prevInput,
      store_email: singleStore?.store_email,
      store_name: singleStore?.store_name,
      store_phone: singleStore?.store_phone,
      store_address: singleStore?.store_address,
      store_categories: singleStore?.store_categories,
      store_tags: singleStore?.store_tags,
      store_city: singleStore?.store_city,
      store_country: singleStore?.store_country,
      store_state: singleStore?.store_state,
      store_operating_status: singleStore?.store_operating_status,
    }));
  }, [singleStore, setInput]);

  useEffect(() => {
    updateInput();
  }, [updateInput]);

  useEffect(() => {
    let newImageUrls = [];
    const newImage64 = [];

    images?.length > 0 &&
      images.forEach((image) => {
        if ("File" in window && image instanceof File) {
          newImageUrls.push(URL.createObjectURL(image));
          let reader = new FileReader();
          reader.readAsDataURL(image);
          reader.onloadend = () => {
            newImage64.push(reader.result);
          };
        } else {
          newImageUrls.push(image);
        }
      });
    setImageUrls(newImageUrls);
    setImage64(newImage64);
  }, [images]);

  const [fileArray, setFileArray] = useState([]);
  const [bannersUrl, setBannersUrl] = useState([]);

  const handleBannerClick = (event) => {
    event.preventDefault();
    hiddenBannerInput.current.click();
  };
  const uploadMultipleFiles = (e) => {
    setFileArray([...e.target.files]);
  };

  useEffect(() => {
    if (fileArray.length < 1) return;
    const newImageUrls = [];
    fileArray.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
    setBannersUrl(newImageUrls);
  }, [fileArray]);

  const handleSubmit = async () => {
    let newInput = {
      ...input,
      base64_photos: image64 ? image64 : [],
      banners: fileArray ? fileArray : [],
    };
    cleanInput(newInput);
    setLoading(true);
    axios
      .post("https://server.food.test.notstore.xyz/api/store/update", newInput, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data;`,
          Authorization: `Bearer ${getToken()}`,
          Team: `${getTeamFromCookies()}`,
        },
      })
      .then((response) => {
        setLoading(false);
        successHandler(response, true);
        navigate(`/admin/$${outlet?.store_name}/settings/store`);
      })
      .catch((error) => {
        //handle error
        setLoading(false);
        errorHandler(error, true);
      });
  };

  return (
    <div data-test="openingHours-page">
      <div className="bg-white border w-full px-4 sm:px-6 sm:max-w-6xl mx-auto py-4 mt-8">
        <p>
          Manage your store details, make changes to your store details here.
        </p>
      </div>
      <div className="pt-8 justify-center items-center  mx-auto sm:max-w-6xl">
        <div className=" p-5 w-full bg-white rounded-sm border sm:p-8  ">
          <div className="flex justify-between items-center my-8">
            <h3 className="text-base text-gray-500 font-meduim flex justify-center mb-3  ">
              Store Information
            </h3>
          </div>

          <div className="border-b mb-5"></div>
          <form className="sm:max-w-[570px]">
            <Input.Label
              title={"Store Name"}
              type="text"
              name="store_name"
              id="store_name"
              defaultValue={store_name}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              placeholder="Enter store Name"
              onChange={handleChange}
            />
            <Input.Label
              title={"Store Email"}
              type="email"
              name="store_email"
              id="store_email"
              defaultValue={store_email}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              placeholder="Enter store email"
              onChange={handleChange}
            />
            <Input.Phone
              title={"store Phone Number"}
              name={"store_phone"}
              id={"store_phone"}
              defaultCountry={"NG"}
              value={store_phone}
              htmlFor={"store_phone"}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              onChange={handleStorePhone}
            />
            <Input.Select
              title={"Store Status"}
              type="text"
              name="store_operating_status"
              id="store_operating_status"
              value={store_operating_status}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              onChange={handleChange}
            >
              <option value={"open"}>Open</option>
              <option value={"closed"}>Closed</option>
            </Input.Select>
            <div>
              <label
                htmlFor="store_categories"
                className="text-sm font-medium text-[#151515]"
              >
                Store Categories
              </label>
              <SelectableInput
                options={storeCategories}
                label="store_categories"
                name={"+ Add Category"}
                type="category"
                previousSelected={store_categories}
                selectOption={handleAddCategories}
              />
            </div>
            <div>
              <label
                htmlFor="store_tags"
                className="text-sm font-medium text-[#151515]"
              >
                Store Tags
              </label>
              <SelectableInput
                options={storeTags}
                label="store_tags"
                name={"+ Add Tag"}
                type="tag"
                previousSelected={store_tags}
                selectOption={handleAddTags}
              />
            </div>
            <Location
              country_id={locationId.country_id}
              state_id={locationId.state_id}
              city_id={locationId.city_id}
              countryList={(value) => {
                setLocationList({
                  ...locationList,
                  countryList: value,
                });
              }}
              stateList={(value) => {
                setLocationList({
                  ...locationList,
                  stateList: value,
                });
              }}
              cityList={(value) => {
                setLocationList({ ...locationList, cityList: value });
              }}
            />

            <div className="mt-6">
              <Input.Select
                title={"Select Country"}
                name="country"
                id="country"
                defaultValue={store_country}
                className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                htmlFor={"country"}
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    country_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>{store_country ?? "Select Country"}</option>
                {locationList.countryList &&
                  locationList.countryList.length > 0 &&
                  locationList.countryList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({
                          name: item.name,
                          id: item.id,
                        })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </Input.Select>
            </div>
            <div className="mt-6">
              <Input.Select
                title={"Select State"}
                name="state"
                id="state"
                defaultValue={store_state}
                className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                htmlFor={"state"}
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    state_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>{store_state ?? "Select State"}</option>
                {locationList.stateList &&
                  locationList.stateList.length > 0 &&
                  locationList.stateList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({
                          name: item.name,
                          id: item.id,
                        })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </Input.Select>
            </div>
            <div className="mt-6">
              <Input.Select
                title={"Select City"}
                name="city"
                id="city"
                defaultValue={store_city}
                className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                htmlFor={"city"}
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    city_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>{store_city ?? "Select City"}</option>
                {locationList.cityList &&
                  locationList.cityList.length > 0 &&
                  locationList.cityList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({
                          name: item.name,
                          id: item.id,
                        })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </Input.Select>
            </div>

            <Input.Label
              title={"Store Address"}
              type="text"
              name="store_address"
              id="store_address"
              defaultValue={store_address}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              placeholder="Enter store Address"
              onChange={handleChange}
            />
          </form>
        </div>
        <div className="border border-[#E4E4F3]  bg-white rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <div>
              <h3 className="text-lg font-medium">
                Store Images (200px by 200px)
              </h3>
            </div>
            <>
              <button className="text-primary" onClick={handleFileClick}>
                + Upload Image
              </button>
              <input
                type="file"
                className="hidden"
                accept="image/*"
                onChange={onImageChange}
                ref={hiddenFileInput}
              />
            </>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-6 gap-8 mt-8">
            {imageUrls?.length > 0 ? (
              imageUrls?.map((image, index) => (
                <figure className="w-full h-[160px] relative" key={index}>
                  <img
                    src={image}
                    className="w-[160px] h-[160px]"
                    alt="merchandise"
                  />
                  <button
                    className="h-8 w-8 bg-[#FAE9E6] rounded-full absolute right-2 top-2"
                    onClick={() => removeFile(index)}
                  >
                    <XIcon className="h-5 w-5 text-primary mx-auto" />
                  </button>
                </figure>
              ))
            ) : (
              <p>No Images.</p>
            )}
          </div>
        </div>
        <div className="border border-[#E4E4F3]  bg-white rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <div>
              <h3 className="text-lg font-medium">
                Store Banner (1440px by 576px)
              </h3>
            </div>
            <>
              <button className="text-primary" onClick={handleBannerClick}>
                + Upload Banner
              </button>
              <input
                type="file"
                className="hidden"
                accept="image/*"
                onChange={uploadMultipleFiles}
                ref={hiddenBannerInput}
              />
            </>
          </div>

          {/* <div className="grid grid-cols-1  gap-8 mt-8">
            {singleStore?.store_banners?.length > 0 ? (
              singleStore?.store_banners.map((image, index) => (
                <figure className="w-full h-[200px] relative" key={index}>
                  <img
                    src={image}
                    className="object-cover w-full h-full"
                    alt="merchandise"
                  />
                </figure>
              ))
            ) : (
              <p>No banners uploaded. Edit store to add Images</p>
            )}
          </div> */}
          <div className="mt-8">
            {bannersUrl?.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-6 gap-8">
                {bannersUrl.map((url, index) => (
                  <figure className="w-full h-[160px] relative" key={index}>
                    <img
                      src={url}
                      className="object-cover w-[160px] h-[160px]"
                      alt="merchandise"
                    />
                  </figure>
                ))}
              </div>
            ) : (
              <p>No images</p>
            )}
          </div>
        </div>
      </div>
      <div className="flex mx-auto justify-center my-14">
        <Button.Primary
          disabled={updating || loading}
          title={loading ? <Beat color={"#ffffff"} /> : "Update Store"}
          className={"w-[160px] rounded-sm"}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
