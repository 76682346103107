import React, { useState } from "react";
import Input from "../../../components/inputs/inputs";
import CreateCustomerModal from "../../../components/modals/admin/createCustomerModal";
import AddOrderItemModal from "../../../components/modals/admin/addOrderItem";

export default function CreateOrder() {
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModalTwo() {
    setOpen(false);
  }

  function openModalTwo() {
    setOpen(true);
  }
  return (
    <div data-test="createOrder-page">
      <CreateCustomerModal show={isOpen} onClose={closeModal} />
      <AddOrderItemModal show={open} onClose={closeModalTwo} />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8">
          <div className="border-b border-[#E4E4F3] py-3 text-center">
            <h3 className="text-lg font-medium">Create New Order</h3>
          </div>

          <div>
            <button className="text-primary" onClick={openModal}>
              Create new customer
            </button>
          </div>

          <form className="mt-8">
            <Input.Label
              className={"border border-[#C2C9D1] w-full"}
              placeholder="Search customer by name, phone number..."
              type={"search"}
              id="Title"
              htmlFor={"Title"}
            />
            <Input.Label
              className={"border border-[#C2C9D1] w-full"}
              placeholder="Search outlet by name, phone number..."
              type={"search"}
              id="Title"
              htmlFor={"Title"}
            />
            <Input.Label
              title={"Order Description"}
              className={"border border-[#C2C9D1] w-full"}
              type={"text"}
              id="Title"
              htmlFor={"Title"}
            />
            <div className="flex gap-4">
              <Input.Select
                title={"Discount"}
                className=""
                type={"text"}
                id="Title"
                htmlFor={"Title"}
              />
              <Input.Label
                title={"Delivery Fee"}
                className={"border border-[#C2C9D1] w-full"}
                placeholder="0"
                type={"number"}
                id="Title"
                htmlFor={"Title"}
              />
            </div>

            <Input.Label
              title={"Delivery Fee"}
              className={"border border-[#C2C9D1] w-full"}
              placeholder="Enter delivery fee"
              type={"text"}
              id="Title"
              htmlFor={"Title"}
            />

            <Input.Select
              title={"Payment Method"}
              className=""
              type={"text"}
              id="Title"
              htmlFor={"Title"}
              placeholder={"Select payment method"}
            />
            <Input.Select
              title={"Payment Status"}
              className=""
              type={"text"}
              id="Title"
              htmlFor={"Title"}
            />
          </form>
        </div>

        <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8">
          <div className="flex justify-between items-start">
            <h2>NotStore</h2>
            <div>
              <p>Order ID: ##967548132</p>
              <p>Date Created: Sep 15, 2021</p>
            </div>
          </div>
          <div className="flex justify-between mt-5">
            <div>
              <p>From</p>
              <p>Ntachi</p>
              <p>+2348090034900</p>
            </div>
            <div className="text-right">
              <p>To</p>
              <p>Kasi Okoro</p>
              <p>+2348023904520</p>
            </div>
          </div>
          <div className="flex justify-between mt-5">
            <div>
              <p>Payment Method</p>
              <p>Flutter wave</p>
            </div>
            <div className="text-right">
              <p>Payment Status</p>
              <p>Paid</p>
            </div>
          </div>
          <button className="text-primary" onClick={openModalTwo}>
            Add New Item
          </button>
          <div className="border" />
          <div className="mt-10">
            <div className="flex justify-between">
              <p>Subtotal</p>
              <p>NGN 3,300.00 </p>
            </div>
            <div className="flex justify-between">
              <p>Discount</p>
              <p>NGN 00.00</p>
            </div>
            <div className="flex justify-between">
              <p>Delivery Fee</p>
              <p>NGN 700.00</p>
            </div>
            <div className="flex justify-between">
              <p>Total</p>
              <p>NGN 4,000.00</p>
            </div>
          </div>

          <div className="border" />
          <p>Comments</p>
        </div>
      </div>
    </div>
  );
}
