import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../buttons/buttons";
import CLOSE from "../../../assets/images/icons/close.svg";
import PaymentSkeleton from "../../skeleton/payment";
import StoreRestaurantFeedback from "./storeRestaurantFeedback";
import { filterFeedback } from "../../../redux/feedback/feedbackActions";
import { getARestaurantOrderAction } from "../../../redux/restaurantOrder/restaurantOrderActions";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default React.forwardRef(function ViewRestaurantOrder(
  {
    visibility = false,
    primaryButtonTitle,
    primaryButton,
    primaryButtonStyle,
    primaryButtonClassName,
    modalTitle,
    modalTitleSection,
    modelContent,
    modelContentSection,
    disableClose = false,
    disableOpen = false,
    onOpen = () => {},
    onClose = () => {},
  },
  ref
) {
  // Ref handler
  React.useImperativeHandle(ref, () => ({
    open: () => openModal(),
    close: () => closeModal(),
    getId: (data) => getOrderId(data),
  }));

  const dispatch = useDispatch();
  let [isOpen, setIsOpen] = React.useState(visibility);
  let [orderId, setOrderId] = React.useState();
  const [isNewFeedback, setIsNewFeedback] = React.useState(false);
  const [isView, setIsView] = React.useState({ id: "", show: false });
  const { isLoading, order } = useSelector((state) => state.restaurantOrder);
  const { loadingFeedback, allFeedback } = useSelector(
    (state) => state.feedback
  );

  useEffect(() => {
    if (orderId) {
      dispatch(getARestaurantOrderAction(orderId));
      dispatch(filterFeedback({ restaurant_order_id: orderId, pagination: 0 }));
    }
    // eslint-disable-next-line
  }, [dispatch, orderId]);

  function closeModal() {
    !disableClose && setIsOpen(false);
    !disableClose && onClose();
    setIsNewFeedback(false);
  }

  function openModal() {
    !disableOpen && setIsOpen(true);
    !disableOpen && onOpen();
  }

  function getOrderId(data) {
    setOrderId(data);
  }

  const toggleViewMore = (feedback_id) => {
    if (isView?.id === feedback_id) {
      setIsView({ id: feedback_id, show: !isView?.show });
    } else {
      setIsView({ id: feedback_id, show: true });
    }
  };

  return (
    <React.Fragment>
      <div className="">
        {primaryButton ? (
          <span className="cursor-pointer" onClick={openModal}>
            {primaryButton}
          </span>
        ) : (
          <button
            type="button"
            style={primaryButtonStyle}
            onClick={openModal}
            disabled={primaryButtonStyle?.disabled}
            className={
              "glade-normal-text-three glade-button-blue whitespace-nowrap w-11/12 py-2 px-4 flex justify-center items-center text-white text-center rounded-sm " +
              primaryButtonClassName
            }
          >
            {primaryButtonTitle ?? "Title"}
          </button>
        )}
      </div>

      <Transition appear show={visibility || isOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay
                className="fixed inset-0"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
              />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="relative w-[629px]  bg-white rounded-sm px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:my-10 sm:max-w-lg sm:w-full sm:p-8">
                    <div className="sm:block absolute top-2 right-3 pt-4 pr-4">
                      <button type="button" className="" onClick={closeModal}>
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>

                    {isNewFeedback ? (
                      <StoreRestaurantFeedback
                        closeModal={closeModal}
                        restaurant_order_id={orderId}
                        setIsNewFeedback={setIsNewFeedback}
                      />
                    ) : (
                      <React.Fragment>
                        <div className="sm:flex sm:items-start">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-meduim text-black"
                          >
                            Order Details
                          </Dialog.Title>
                        </div>

                        <div className="mt-3 sm:mt-2">
                          <div className="border-b-2"></div>
                          {isLoading ? (
                            <PaymentSkeleton />
                          ) : (
                            <>
                              {order?.order_items?.length > 0 &&
                                order?.order_items?.map((product, i) => (
                                  <div key={i}>
                                    <div className="flex justify-between items-start my-5">
                                      <div className="flex">
                                        <span className="w-6 mr-5 sm:mr-10">
                                          {product.quantity}x
                                        </span>
                                        <div className="text-left">
                                          <p className="mb-2">
                                            {product?.product_name}
                                          </p>
                                          {product?.product_attributes?.length >
                                            0 &&
                                            product?.product_attributes?.map(
                                              (attr, i) => (
                                                <p key={i} className="text-sm">
                                                  {attr.quantity}x {attr?.name}{" "}
                                                  - ₦ {attr.amount}
                                                </p>
                                              )
                                            )}
                                        </div>
                                      </div>
                                      <div className="flex gap-4">
                                        <p>
                                          ₦{" "}
                                          {numberWithCommas(
                                            product?.product_amount
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </>
                          )}
                        </div>

                        <div className="mt-3 sm:mt-2 shadow-lg px-4 rounded-md">
                          <div className="border-b-2 py-2 border-[#EAEAEA]">
                            <h3 className="text-left font-medium text-lg">
                              Payment Summary
                            </h3>
                          </div>

                          <div className="flex justify-between items-center py-2">
                            <span>Amount</span>
                            <span className="font-bold text-[#4D4D4D]">
                              ₦{numberWithCommas(order?.order_sub_amount)}
                            </span>
                          </div>
                          <div className="flex justify-between items-center py-2">
                            <span>Delivery Fee</span>
                            <span className="font-bold text-[#4D4D4D]">
                              ₦{numberWithCommas(order?.order_delivery_fee)}
                            </span>
                          </div>
                          <div className="flex justify-between items-center py-2">
                            <span>Service Fee</span>
                            <span className="font-bold text-[#4D4D4D]">
                              ₦{numberWithCommas(order?.order_service_fee)}
                            </span>
                          </div>
                          <div className="flex justify-between items-center py-2">
                            <span>Insurance Fee</span>
                            <span className="font-bold text-[#4D4D4D]">
                              ₦{numberWithCommas(order?.order_insurance_fee)}
                            </span>
                          </div>
                          <div className="flex justify-between items-center py-2">
                            <span>Discount</span>
                            <span className="font-bold text-[#4D4D4D]">
                              ₦{numberWithCommas(order?.order_discount)}
                            </span>
                          </div>
                          <div className="flex justify-between items-center py-2 border-t-2 border-[#EAEAEA]">
                            <span>Total</span>
                            <span className="font-bold text-[#4D4D4D]">
                              ₦{numberWithCommas(order?.order_amount)}
                            </span>
                          </div>
                        </div>

                        {order && order?.order_coupons?.length > 0 && (
                          <div className="mt-4 sm:mt-4 shadow-lg px-4 rounded-md">
                            <div className="border-b-2 py-2 border-[#EAEAEA]">
                              <h3 className="text-left font-medium text-lg">
                                Coupon Applied
                              </h3>
                            </div>

                            <div className="flex justify-between items-center py-2">
                              <span>Code</span>
                              <span className="font-bold text-[#4D4D4D]">
                                {order?.order_coupons?.[0]?.slug}
                              </span>
                            </div>
                            <div className="flex justify-between items-center py-2">
                              <span>Amount</span>
                              <span className="font-bold text-[#4D4D4D]">
                                ₦
                                {numberWithCommas(
                                  order?.order_coupons?.[0]?.amount
                                )}
                              </span>
                            </div>
                          </div>
                        )}

                        {order && order?.order_promotion_codes?.length > 0 && (
                          <div className="mt-4 sm:mt-4 shadow-lg px-4 rounded-md">
                            <div className="border-b-2 py-2 border-[#EAEAEA]">
                              <h3 className="text-left font-medium text-lg">
                                Promo Code Applied
                              </h3>
                            </div>

                            <div className="flex justify-between items-center py-2">
                              <span>Code</span>
                              <span className="font-bold text-[#4D4D4D]">
                                {order?.order_promotion_codes?.[0]?.slug}
                              </span>
                            </div>
                            <div className="flex justify-between items-center py-2">
                              <span>Amount</span>
                              <span className="font-bold text-[#4D4D4D]">
                                ₦
                                {numberWithCommas(
                                  order?.order_promotion_codes?.[0]?.amount
                                )}
                              </span>
                            </div>
                          </div>
                        )}

                        {loadingFeedback ? (
                          <PaymentSkeleton />
                        ) : (
                          <>
                            {allFeedback?.length > 0 ? (
                              <div className="mt-4 sm:mt-4 shadow-lg px-4 rounded-md">
                                <div className="border-b-2 py-2 border-[#EAEAEA]">
                                  <h3 className="text-left font-medium text-lg">
                                    Feedbacks
                                  </h3>
                                </div>
                                <ul className="py-4">
                                  {allFeedback?.map((feedback, i) => (
                                    <li
                                      key={i}
                                      className="flex justify-between items-center py-1 gap-4 mb-2"
                                    >
                                      <div className="text-[14px] text-[#676565] flex items-center gap-3">
                                        <div className="w-[8px] h-[8px] rounded-full bg-[#FB9B11] flex-none" />
                                        <span
                                          className={`${
                                            isView?.show &&
                                            isView?.id === feedback?.id
                                              ? "w-full"
                                              : "w-[180px] sm:w-[200px] truncate"
                                          } text-left text-sm`}
                                        >
                                          {feedback?.description}
                                        </span>
                                      </div>
                                      <button
                                        onClick={() =>
                                          toggleViewMore(feedback?.id)
                                        }
                                        className={`text-sm text-[#676565] italic outline-none flex-none ${
                                          isView.show &&
                                          isView.id === feedback?.id
                                            ? "text-primary"
                                            : ""
                                        }`}
                                      >
                                        {isView.show &&
                                        isView.id === feedback?.id
                                          ? "View less"
                                          : "View more"}
                                      </button>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ) : (
                              <div className="my-4">
                                <p className="text-[18px]">
                                  Thank you for your recent purchase.
                                </p>
                                <p className="text-[18px]">
                                  We are eager to know about your experience
                                  with{" "}
                                  <span className="text-primary">NotStore.</span>{" "}
                                  You may leave us a feedback below or proceed
                                  to home.
                                </p>
                                <p className="py-2 text-[18px]">
                                  We appreciate your time and thoughtful
                                  response.
                                </p>
                              </div>
                            )}
                          </>
                        )}

                        <Button.Primary
                          title={"Leave Feedback"}
                          type="button"
                          className="w-full py-3 mt-6"
                          onClick={() => setIsNewFeedback(true)}
                        />
                        <Button.Secondary
                          title={"Back"}
                          type="button"
                          className="w-full py-3 mt-6"
                          onClick={closeModal}
                        />
                      </React.Fragment>
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
});
