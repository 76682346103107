import React from "react";
// import Logo from "../assets/images/icons/notstore-o.svg";

export default function LazyLoader() {
  return (
    <div className="h-[80vh] w-full flex justify-center items-center">
      {/* <img
        src={Logo}
        alt="Logo"
        className=" w-[40px] h-[40px]"
      /> */}
      <span className="text-primary text-2xl font-bold animated-rotate">NOT</span>
    </div>
  );
}
