import React, { useMemo, useEffect, useState, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../../components/skeleton/table";
import { Pagination } from "../../../../../components/pagination/pagination";
import { filterRestaurantsAction } from "../../../../../redux/restaurant/restaurantActions";

export default function Restaurants({ business_id }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page } = useParams();
  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);

  const [data, setData] = useState([]);
  const [payLoad] = useState({
    start_date: lastMonth,
    end_date: new Date(),
    business_id: business_id,
  });

  const { allRestaurants } = useSelector((state) => state.restaurant);
  const { filtering, searching } = useSelector((state) => state.restaurant);

  useEffect(() => {
    dispatch(filterRestaurantsAction(payLoad, page));
    // eslint-disable-next-line
  }, [page]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((restaurant, index) => {
        let show = {
          id: index + 1,
          name: restaurant?.restaurant_name,
          phone: restaurant?.restaurant_phone,
          status: (
            <span>
              {restaurant?.management_approved ? (
                <span className="text-[#00D220] font-bold">Approved</span>
              ) : (
                <span className="text-primary font-bold">Unapproved</span>
              )}
            </span>
          ),
          location: restaurant?.restaurant_city,
          email: restaurant?.restaurant_email,
          restaurant: restaurant,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  useLayoutEffect(() => {
    const dataSet = createTableData(allRestaurants?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRestaurants]);

  function goToSinglePage(params) {
    let id = params?.show?.restaurant?.id;

    navigate(`/admin/orders-report/restaurant/${id}/shipped/1`, {
      state: { restaurant: params?.show?.restaurant },
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.id",
          },
          {
            Header: "Restuarant Name",
            accessor: "show.name",
          },
          {
            Header: "Phone Number",
            accessor: "show.phone",
          },
          {
            Header: "Status",
            accessor: "show.status",
          },
          {
            Header: "Location",
            accessor: "show.location",
          },
          {
            Header: "Email",
            accessor: "show.email",
          },
        ],
      },
    ],
    []
  );
  return (
    <div>
      <div className="bg-white rounded-md">
        {filtering || searching ? (
          <TableSkeleton />
        ) : (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        )}

        <Pagination
          data={allRestaurants}
          route={"admin/report/business/restaurant"}
        />
      </div>
    </div>
  );
}
