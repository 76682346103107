import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { cartService } from "./../../services/cart.service";
import cartType from "./cartTypes";

/**
 * GetAllCarts
 *
 */
export const getAllCarts = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: cartType["LOADING_CART"],
      payLoad: true,
    });
    const response = await cartService.getAllCarts(payLoad);
    dispatch({
      type: cartType["LOADING_CART"],
      payLoad: false,
    });
    dispatch({
      type: cartType["GET_ALL_CARTS"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: cartType["LOADING_CART"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * StoreCarts
 * @param {object} payLoad{ name:"gaming",types: skilled}
 * @returns {void}
 * */
export const addToCart = (payLoad) => async (dispatch) => {
  try {
    const response = await cartService.storeCart({
      ...payLoad,
    });
    dispatch({
      type: cartType["LOADING_CART"],
      payLoad: false,
    });
    dispatch({
      type: cartType["STORE_CART"],
      payLoad: response,
    });
    successHandler(response, true);
  } catch (error) {
    dispatch({
      type: cartType["LOADING_CART"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * Delete Cart
 * @param {object} payLoad{ id:"22"}
 * @returns {void}
 * */
export const deleteCart = (payLoad) => async (dispatch) => {
  try {
    const response = await cartService.deleteCart({
      ...payLoad,
    });
    dispatch({
      type: cartType["LOADING_CART"],
      payLoad: false,
    });
    dispatch({
      type: cartType["DELETE_CART"],
      payLoad: response,
    });
    successHandler(response, true);
  } catch (error) {
    dispatch({
      type: cartType["LOADING_CART"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * Edit Cart
 * @param {object} payLoad {id:"22"}
 * @returns {void}
 * */
export const editCart = (payLoad) => async (dispatch) => {
  dispatch({
    type: cartType["RELOADING_CART"],
    payLoad: true,
  });
  try {
    const response = await cartService.editCart({
      ...payLoad,
    });

    dispatch({
      type: cartType["LOADING_CART"],
      payLoad: false,
    });

    dispatch({
      type: cartType["RELOADING_CART"],
      payLoad: false,
    });

    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: cartType["LOADING_CART"],
      payLoad: false,
    });
    dispatch({
      type: cartType["RELOADING_CART"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * updateCoupon
 * @param {object} payLoad {id:"22"}
 * @returns {void}
 * */
export const updateCoupon = (payLoad) => async (dispatch) => {
  dispatch({
    type: cartType["UPDATING_COUPON"],
    payLoad: true,
  });
  try {
    const response = await cartService.editCart({
      ...payLoad,
    });
    dispatch({
      type: cartType["LOADING_CART"],
      payLoad: false,
    });
    dispatch({
      type: cartType["UPDATING_COUPON"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: cartType["LOADING_CART"],
      payLoad: false,
    });
    dispatch({
      type: cartType["UPDATING_COUPON"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * searchCarts
 * @param {object} payLoad {search:"notstore"}
 * @returns {void}
 * */
export const searchCarts = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: cartType["LOADING_SEARCH"],
      payLoad: true,
    });
    const response = await cartService.searchCart({
      ...payLoad,
    });
    dispatch({
      type: cartType["LOADING_SEARCH"],
      payLoad: false,
    });
    dispatch({
      type: cartType["SEARCH_CARTS"],
      payLoad: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: cartType["LOADING_SEARCH"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
/**
 * Edit Cart
 * @param {object} payLoad {company_id :"7b3e58e0-b68c-498d-b8d5-82198ef6d481"}
 * @returns {void}
 * */
export const filterCarts = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: cartType["FILTERING"],
      payLoad: true,
    });
    const response = await cartService.filterCart(payLoad);
    dispatch({
      type: cartType["FILTERING"],
      payLoad: false,
    });
    dispatch({
      type: cartType["FILTER_CARTS"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: cartType["FILTERING"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
/**
 * Show Cart
 * @param {object} payLoad {properties:1}
 * */
export const showCart = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: cartType["LOADING_CART"],
      payLoad: true,
    });
    const response = await cartService.showCart(payLoad);
    dispatch({
      type: cartType["LOADING_CART"],
      payLoad: false,
    });

    dispatch({
      type: cartType["SHOW_CART"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: cartType["LOADING_CART"],
      payLoad: false,
    });

    errorHandler(error, true);
  }
};
export const checkoutCart = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: cartType["PROCESSING"],
      payLoad: true,
    });
    const response = await cartService.checkoutCart(payLoad);

    dispatch({
      type: cartType["PROCESSING"],
      payLoad: false,
    });
    dispatch({
      type: cartType["CHECKOUT_CART"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: cartType["PROCESSING"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
/**
 * Show my Cart
 * @param {object} payLoad {properties:1}
 * */
export const showMyCart = () => async (dispatch) => {
  try {
    const response = await cartService.showMyCart();
    dispatch({
      type: cartType["LOADING_CART"],
      payLoad: false,
    });
    dispatch({
      type: cartType["SHOW_MY_CART"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: cartType["LOADING_CART"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Show my Cart
 * @param {object} payLoad {properties:1}
 * */
export const showMyCartNoProperties = () => async (dispatch) => {
  try {
    const response = await cartService.showMyCartNoProperties();
    dispatch({
      type: cartType["LOADING_CART"],
      payLoad: false,
    });
    dispatch({
      type: cartType["SHOW_MY_CART_NO_PROPERTIES"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: cartType["LOADING_CART"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * empty Cart
 * @param {object} payLoad  {id:"22"}
 * */
export const emptyCart = (payLoad) => async (dispatch) => {
  try {
    const response = await cartService.emptyCart({
      ...payLoad,
    });

    dispatch({
      type: cartType["LOADING_CART"],
      payLoad: false,
    });
    dispatch({
      type: cartType["EMPTY_CART"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: cartType["LOADING_CART"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

export const storeCartProducts = (payLoad) => async (dispatch) => {
  dispatch({
    type: cartType["CART_PRODUCTS"],
    payLoad: payLoad,
  });
};

export const storePublicCartProducts = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: cartType["STORING_PUBLIC_CART"],
      payLoad: true,
    });
    const response = await cartService.publicCart(payLoad);
    dispatch({
      type: cartType["STORING_PUBLIC_CART"],
      payLoad: false,
    });
    dispatch({
      type: cartType["CART_PRODUCTS"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: cartType["STORING_PUBLIC_CART"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};
